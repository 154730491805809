import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { configureClient } from './api/client';
import HttpsRedirect from './components/HttpsRedirect';
import App from './modules/app/App';
import * as serviceWorker from './serviceWorker';
import { configureStore, StoreState } from './store';

const store: Store<StoreState> = configureStore();
configureClient(store);

ReactDOM.render(
    <Provider store={store}>
        <HttpsRedirect>
            <App />
        </HttpsRedirect>
    </Provider>,
    document.getElementById('root') as HTMLElement
);

serviceWorker.unregister();
