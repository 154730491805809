import { Reducer } from 'redux';
import { AuthAction, AuthActionType } from '../actions';
import localStore from '../LocalStore';
import { Auth } from '../StoreState';

type ReducerState = Auth | null;
type ReducerAction = AuthAction;

const initialState: ReducerState = localStore.auth();

export const auth: Reducer<ReducerState, ReducerAction> = (
    state: ReducerState = initialState,
    action: ReducerAction
): ReducerState => {
    switch (action.type) {
        case AuthActionType.AuthenticateUser:
            return {
                date: new Date(),
                token: action.token.token,
            };
        case AuthActionType.LogOutUser:
            return null;
        default:
            return state;
    }
};
