import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import React from 'react';
import { ArticleItem } from '../../api/response';

// #region UI
const checkGreenColor: string = '#377D22';
const dateTimeFormat: string = 'YYYY-MM-DD HH:mm';

const strings = {
    button: {
        delete: 'Usuń',
        edit: 'Edytuj',
    },
    text: {
        noValue: '-',
    },
};
// #endregion

// #region Props & State
interface ComponentProps {
    item: ArticleItem;
    onDeleteClick: (item: ArticleItem) => void;
    onEditClick: (item: ArticleItem) => void;
}
// #endregion

class ArticleTableRow extends React.Component<ComponentProps> {
    // #region Lifecycle
    public shouldComponentUpdate(nextProps: Readonly<ComponentProps>): boolean {
        const currentItem = this.props.item;
        const nextItem = nextProps.item;

        return (
            currentItem.id !== nextItem.id ||
            currentItem.title !== nextItem.title ||
            currentItem.publicationDate !== nextItem.publicationDate
        );
    }

    public render(): React.ReactNode {
        const { item } = this.props;

        return (
            <TableRow hover={true}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.title}</TableCell>
                <TableCell>
                    {item.publicationDate !== null
                        ? moment(item.publicationDate).format(dateTimeFormat)
                        : strings.text.noValue}
                </TableCell>
                <TableCell>{item.isPublished && <DoneIcon htmlColor={checkGreenColor} />}</TableCell>
                <TableCell>
                    <Tooltip title={strings.button.edit}>
                        <IconButton onClick={this.onEditActionClick}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={strings.button.delete}>
                        <IconButton onClick={this.onDeleteActionClick}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    }
    // #endregion

    // #region Private
    private onEditActionClick = (): void => {
        const { item, onEditClick } = this.props;
        onEditClick(item);
    };

    private onDeleteActionClick = (): void => {
        const { item, onDeleteClick } = this.props;
        onDeleteClick(item);
    };
    // #endregion
}

export default ArticleTableRow;
