import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import moment from 'moment';
import React from 'react';
import { Version } from '../../api/response';

// #region UI
const checkGreenColor: string = '#377D22';
const dateTimeFormat: string = 'YYYY-MM-DD HH:mm:ss';

const strings = {
    button: {
        delete: 'Usuń',
    },
};
// #endregion

// #region Props & State
interface ComponentProps {
    item: Version;
    onDeleteClick: (item: Version) => void;
}
// #endregion

class VersionTableRow extends React.Component<ComponentProps> {
    // #region Lifecycle
    public shouldComponentUpdate(nextProps: Readonly<ComponentProps>): boolean {
        const currentItem = this.props.item;
        const nextItem = nextProps.item;

        return (
            currentItem.id !== nextItem.id ||
            currentItem.createdAt !== nextItem.createdAt ||
            currentItem.isCurrent !== nextItem.isCurrent
        );
    }

    public render(): React.ReactNode {
        const { item } = this.props;

        return (
            <TableRow hover={true}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.isCurrent && <DoneIcon htmlColor={checkGreenColor} />}</TableCell>
                <TableCell>{moment(item.createdAt).format(dateTimeFormat)}</TableCell>
                <TableCell>
                    {!item.isCurrent && (
                        <Tooltip title={strings.button.delete}>
                            <IconButton onClick={this.onDeleteActionClick}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </TableCell>
            </TableRow>
        );
    }
    // #endregion

    // #region Private
    private onDeleteActionClick = (): void => {
        const { item, onDeleteClick } = this.props;
        onDeleteClick(item);
    };
    // #endregion
}

export default VersionTableRow;
