import { CircularProgress, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { API, healthInfluenceTypeText, ingredientTypeText, nutritionalValueTypeText } from '../../api';
import { Ingredient } from '../../api/response';
import withAppCanvas, { AppCanvasComponentProps } from '../../components/AppCanvas';
import { MenuItemType } from '../../model/menuItems';

// #region UI
const strings = {
    error: {
        notFound: 'Składnik o podanym identyfikatorze nie istnieje.',
    },
    label: {
        accuracy: 'Dokładność wyszukiwania',
        betaCarotene: 'β-karoten [µg]',
        calcium: 'Wapń [mg]',
        carbohydrate: 'Węglowodany [g]',
        categories: 'Kategorie',
        cholesterol: 'Cholesterol [mg]',
        copper: 'Miedź [mg]',
        details: 'Opis',
        energyInKilocalorie: 'Wartość energetyczna [kJ]',
        energyInKilojoule: 'Wartość energetyczna [kcal]',
        fat: 'Tłuszcz [g]',
        fibre: 'Błonnik pokarmowy [g]',
        folate: 'Foliany [µg]',
        healthInfluenceDescription: 'Wpływ na zdrowie (opis)',
        healthInfluenceType: 'Wpływ na zdrowie (typ)',
        id: 'Identyfikator',
        information: 'Dodatkowy opis',
        iodine: 'Jod [µg]',
        iron: 'Żelazo [mg]',
        magnesium: 'Magnez [mg]',
        manganese: 'Mangan [mg]',
        monounsaturatedFattyAcid: 'Kwasy tłuszczowe jednonienasycone [g]',
        name: 'Nazwa',
        niacin: 'Niacyna [mg]',
        nutritionalValueType: 'Typ wartości odżywczej',
        phosphorus: 'Fosfor [mg]',
        photoFilename: 'Zdjęcie',
        polyunsaturatedFattyAcid: 'Kwasy tłuszczowe wielonienasycone [g]',
        potassium: 'Potas [mg]',
        protein: 'Białko [g]',
        retinol: 'Retinol [µg]',
        riboflavin: 'Ryboflawina [mg]',
        salt: 'Sól [g]',
        saturatedFattyAcid: 'Kwasy tłuszczowe nasycone [g]',
        similarIngredients: 'Podobne składniki',
        sodium: 'Sód [mg]',
        sugars: 'Cukry [g]',
        synonyms: 'Synonimy',
        tags: 'Tagi',
        thiamine: 'Tiamina [mg]',
        type: 'Typ',
        vitaminA: 'Witamina A [µg]',
        vitaminB12: 'Witamina B₁₂ [µg]',
        vitaminB6: 'Witamina B₆ [mg]',
        vitaminC: 'Witamina C [mg]',
        vitaminD: 'Witamina D [µg]',
        vitaminE: 'Witamina E [mg]',
        zinc: 'Cynk [mg]',
    },
    text: {
        noValue: '-',
    },
};
const styles = () =>
    createStyles({
        multilineText: {
            whiteSpace: 'pre-line',
        },
        photo: {
            height: 64,
            objectFit: 'cover',
            width: 64,
        },
    });
// #endregion

// #region Props & State
interface RouteParams {
    ingredientId: string;
}
interface ComponentProps extends WithStyles<typeof styles>, RouteComponentProps<RouteParams>, AppCanvasComponentProps {}

interface ComponentState {
    item: Ingredient | null;
    loading: boolean;
}

const initialState: ComponentState = {
    item: null,
    loading: true,
};
// #endregion

class IngredientDetailsPage extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = initialState;

        const { dispatchChangeMenuItem, dispatchSetTitle } = this.props;
        dispatchChangeMenuItem(MenuItemType.Ingredients);
        dispatchSetTitle(MenuItemType.Ingredients);

        this.load();
    }

    // #region Lifecycle
    public render(): React.ReactNode {
        const { loading, item } = this.state;
        const { classes } = this.props;

        if (loading) {
            return (
                <Grid container={true} justify="center">
                    <CircularProgress />
                </Grid>
            );
        }

        if (item === null) {
            return <Typography color="error">{strings.error.notFound}</Typography>;
        }

        return (
            <List>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText primary={item.id} secondary={strings.label.id} />
                </ListItem>

                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText primary={ingredientTypeText(item.type)} secondary={strings.label.type} />
                </ListItem>

                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText primary={item.name} secondary={strings.label.name} />
                </ListItem>

                {item.synonyms.length === 0 && (
                    <ListItem divider={true} button={true} disableRipple={true}>
                        <ListItemText primary={strings.text.noValue} secondary={strings.label.synonyms} />
                    </ListItem>
                )}

                {item.synonyms.length > 0 && (
                    <ListItem divider={true} button={true} disableRipple={true}>
                        <ListItemText
                            primary={
                                <List>
                                    {item.synonyms.map((synonym, index) => (
                                        <ListItem key={index} divider={false} disableGutters={true} dense={true}>
                                            <ListItemText primary={synonym} />
                                        </ListItem>
                                    ))}
                                </List>
                            }
                            secondary={strings.label.synonyms}
                        />
                    </ListItem>
                )}

                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        classes={{ primary: classes.multilineText }}
                        primary={item.details}
                        secondary={strings.label.details}
                    />
                </ListItem>

                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        classes={{ primary: classes.multilineText }}
                        primary={item.information !== null ? item.information : strings.text.noValue}
                        secondary={strings.label.information}
                    />
                </ListItem>

                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        classes={{ primary: classes.multilineText }}
                        primary={
                            item.healthInfluenceDescription !== null
                                ? item.healthInfluenceDescription
                                : strings.text.noValue
                        }
                        secondary={strings.label.healthInfluenceDescription}
                    />
                </ListItem>

                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={
                            item.healthInfluenceType !== null
                                ? healthInfluenceTypeText(item.healthInfluenceType)
                                : strings.text.noValue
                        }
                        secondary={strings.label.healthInfluenceType}
                    />
                </ListItem>

                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={
                            <img
                                alt={item.name}
                                className={classes.photo}
                                src={API.ingredients.photoUrl(item.id, item.photoFilename)}
                            />
                        }
                        secondary={strings.label.photoFilename}
                    />
                </ListItem>

                {item.categories.length === 0 && (
                    <ListItem divider={true} button={true} disableRipple={true}>
                        <ListItemText primary={strings.text.noValue} secondary={strings.label.categories} />
                    </ListItem>
                )}

                {item.categories.length > 0 && (
                    <ListItem divider={true} button={true} disableRipple={true}>
                        <ListItemText
                            primary={
                                <List>
                                    {item.categories.map((category, index) => (
                                        <ListItem key={index} divider={false} disableGutters={true} dense={true}>
                                            <ListItemText primary={category.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            }
                            secondary={strings.label.categories}
                        />
                    </ListItem>
                )}

                {item.tags.length === 0 && (
                    <ListItem divider={true} button={true} disableRipple={true}>
                        <ListItemText primary={strings.text.noValue} secondary={strings.label.tags} />
                    </ListItem>
                )}

                {item.tags.length > 0 && (
                    <ListItem divider={true} button={true} disableRipple={true}>
                        <ListItemText
                            primary={
                                <List>
                                    {item.tags.map((tag, index) => (
                                        <ListItem key={index} divider={false} disableGutters={true} dense={true}>
                                            <ListItemText primary={tag} />
                                        </ListItem>
                                    ))}
                                </List>
                            }
                            secondary={strings.label.tags}
                        />
                    </ListItem>
                )}

                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText primary={`${item.accuracy}%`} secondary={strings.label.accuracy} />
                </ListItem>

                {item.similarIngredients.length === 0 && (
                    <ListItem divider={true} button={true} disableRipple={true}>
                        <ListItemText primary={strings.text.noValue} secondary={strings.label.similarIngredients} />
                    </ListItem>
                )}

                {item.similarIngredients.length > 0 && (
                    <ListItem divider={true} button={true} disableRipple={true}>
                        <ListItemText
                            primary={
                                <List>
                                    {item.similarIngredients.map((ingredient, index) => (
                                        <ListItem key={index} divider={false} disableGutters={true} dense={true}>
                                            <ListItemText primary={ingredient.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            }
                            secondary={strings.label.similarIngredients}
                        />
                    </ListItem>
                )}

                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={
                            item.nutritionalValueType !== null
                                ? nutritionalValueTypeText(item.nutritionalValueType)
                                : strings.text.noValue
                        }
                        secondary={strings.label.nutritionalValueType}
                    />
                </ListItem>

                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.energyInKilocalorie !== null ? item.energyInKilocalorie : strings.text.noValue}
                        secondary={strings.label.energyInKilocalorie}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.energyInKilojoule !== null ? item.energyInKilojoule : strings.text.noValue}
                        secondary={strings.label.energyInKilojoule}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.protein !== null ? item.protein : strings.text.noValue}
                        secondary={strings.label.protein}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.fat !== null ? item.fat : strings.text.noValue}
                        secondary={strings.label.fat}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.saturatedFattyAcid !== null ? item.saturatedFattyAcid : strings.text.noValue}
                        secondary={strings.label.saturatedFattyAcid}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={
                            item.monounsaturatedFattyAcid !== null
                                ? item.monounsaturatedFattyAcid
                                : strings.text.noValue
                        }
                        secondary={strings.label.monounsaturatedFattyAcid}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={
                            item.polyunsaturatedFattyAcid !== null
                                ? item.polyunsaturatedFattyAcid
                                : strings.text.noValue
                        }
                        secondary={strings.label.polyunsaturatedFattyAcid}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.carbohydrate !== null ? item.carbohydrate : strings.text.noValue}
                        secondary={strings.label.carbohydrate}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.sugars !== null ? item.sugars : strings.text.noValue}
                        secondary={strings.label.sugars}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.fibre !== null ? item.fibre : strings.text.noValue}
                        secondary={strings.label.fibre}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.sodium !== null ? item.sodium : strings.text.noValue}
                        secondary={strings.label.sodium}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.salt !== null ? item.salt : strings.text.noValue}
                        secondary={strings.label.salt}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.potassium !== null ? item.potassium : strings.text.noValue}
                        secondary={strings.label.potassium}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.calcium !== null ? item.calcium : strings.text.noValue}
                        secondary={strings.label.calcium}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.phosphorus !== null ? item.phosphorus : strings.text.noValue}
                        secondary={strings.label.phosphorus}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.magnesium !== null ? item.magnesium : strings.text.noValue}
                        secondary={strings.label.magnesium}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.iron !== null ? item.iron : strings.text.noValue}
                        secondary={strings.label.iron}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.zinc !== null ? item.zinc : strings.text.noValue}
                        secondary={strings.label.zinc}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.copper !== null ? item.copper : strings.text.noValue}
                        secondary={strings.label.copper}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.manganese !== null ? item.manganese : strings.text.noValue}
                        secondary={strings.label.manganese}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.iodine !== null ? item.iodine : strings.text.noValue}
                        secondary={strings.label.iodine}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.vitaminA !== null ? item.vitaminA : strings.text.noValue}
                        secondary={strings.label.vitaminA}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.retinol !== null ? item.retinol : strings.text.noValue}
                        secondary={strings.label.retinol}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.betaCarotene !== null ? item.betaCarotene : strings.text.noValue}
                        secondary={strings.label.betaCarotene}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.vitaminD !== null ? item.vitaminD : strings.text.noValue}
                        secondary={strings.label.vitaminD}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.vitaminE !== null ? item.vitaminE : strings.text.noValue}
                        secondary={strings.label.vitaminE}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.thiamine !== null ? item.thiamine : strings.text.noValue}
                        secondary={strings.label.thiamine}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.riboflavin !== null ? item.riboflavin : strings.text.noValue}
                        secondary={strings.label.riboflavin}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.niacin !== null ? item.niacin : strings.text.noValue}
                        secondary={strings.label.niacin}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.vitaminB6 !== null ? item.vitaminB6 : strings.text.noValue}
                        secondary={strings.label.vitaminB6}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.folate !== null ? item.folate : strings.text.noValue}
                        secondary={strings.label.folate}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.vitaminB12 !== null ? item.vitaminB12 : strings.text.noValue}
                        secondary={strings.label.vitaminB12}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.vitaminC !== null ? item.vitaminC : strings.text.noValue}
                        secondary={strings.label.vitaminC}
                    />
                </ListItem>
                <ListItem divider={true} button={true} disableRipple={true}>
                    <ListItemText
                        primary={item.cholesterol !== null ? item.cholesterol : strings.text.noValue}
                        secondary={strings.label.cholesterol}
                    />
                </ListItem>
            </List>
        );
    }
    // #endregion

    // #region Private
    private load = async (): Promise<void> => {
        try {
            const item = await API.ingredients.detail(parseInt(this.props.match.params.ingredientId, 10));
            this.setState({ loading: false, item });
        } catch (error) {
            this.setState({ loading: false });
        }
    };
    // #endregion
}

export default withAppCanvas(withStyles(styles)(IngredientDetailsPage));
