import { Action, ActionCreator } from 'redux';
import { MenuItemType } from '../../model/menuItems';
import { MenuItemActionType } from './actionTypes';

// #region ChangeMenuItem
export interface ChangeMenuItemAction extends Action<MenuItemActionType.ChangeMenuItem> {
    current: MenuItemType;
}

export const changeMenuItem: ActionCreator<ChangeMenuItemAction> = (current: MenuItemType): ChangeMenuItemAction => ({
    current,
    type: MenuItemActionType.ChangeMenuItem,
});
// #endregion

// #region ClearMenuItem
export interface ClearMenuItemAction extends Action<MenuItemActionType.ClearMenuItem> {}

export const clearMenuItem: ActionCreator<ClearMenuItemAction> = (): ClearMenuItemAction => ({
    type: MenuItemActionType.ClearMenuItem,
});
// #endregion

export type MenuItemAction = ChangeMenuItemAction | ClearMenuItemAction;
