import {
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { API, dietIngredientInfluenceText, DietIngredientRecommendedType } from '../../api';
import { Diet } from '../../api/response';
import withAppCanvas, { AppCanvasComponentProps } from '../../components/AppCanvas';
import { MenuItemType } from '../../model/menuItems';

// #region UI
const strings = {
    column: {
        details: 'Opis',
        id: 'Id',
        influence: 'Wypływ',
        name: 'Nazwa',
        recommend: 'Polecaj',
    },
    error: {
        notFound: 'Dieta o podanym identyfikatorze nie istnieje.',
    },
    label: {
        details: 'Opis',
        id: 'Identyfikator',
        ingredients: 'Składniki',
        isDefault: 'Czy domyślna',
        name: 'Nazwa',
    },
    text: {
        no: 'Nie',
        yes: 'Tak',
    },
};
const styles = () =>
    createStyles({
        multilineText: {
            whiteSpace: 'pre-line',
        },
    });
// #endregion

// #region Props & State
interface RouteParams {
    dietId: string;
}
interface ComponentProps extends WithStyles<typeof styles>, RouteComponentProps<RouteParams>, AppCanvasComponentProps {}

interface ComponentState {
    item: Diet | null;
    loading: boolean;
}

const initialState: ComponentState = {
    item: null,
    loading: true,
};
// #endregion

class DietDetailsPage extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = initialState;

        const { dispatchChangeMenuItem, dispatchSetTitle } = this.props;
        dispatchChangeMenuItem(MenuItemType.Diets);
        dispatchSetTitle(MenuItemType.Diets);

        this.load();
    }

    // #region Lifecycle
    public render(): React.ReactNode {
        const { loading, item } = this.state;
        const { classes } = this.props;

        if (loading) {
            return (
                <Grid container={true} justify="center">
                    <CircularProgress />
                </Grid>
            );
        }

        if (item === null) {
            return <Typography color="error">{strings.error.notFound}</Typography>;
        }

        return (
            <Grid container={true} direction="column" spacing={1}>
                <Grid item={true}>
                    <List>
                        <ListItem divider={true} button={true} disableRipple={true}>
                            <ListItemText primary={item.id} secondary={strings.label.id} />
                        </ListItem>

                        <ListItem divider={true} button={true} disableRipple={true}>
                            <ListItemText primary={item.name} secondary={strings.label.name} />
                        </ListItem>

                        <ListItem divider={true} button={true} disableRipple={true}>
                            <ListItemText
                                primary={item.isDefault ? strings.text.yes : strings.text.no}
                                secondary={strings.label.isDefault}
                            />
                        </ListItem>

                        <ListItem divider={true} button={true} disableRipple={true}>
                            <ListItemText
                                classes={{ primary: classes.multilineText }}
                                primary={item.details}
                                // primary={
                                //     <Typography
                                //         variant="body1"
                                //         component="span"
                                //         dangerouslySetInnerHTML={{ __html: item.details }}
                                //     />
                                // }
                                secondary={strings.label.details}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item={true}>
                    <Typography variant="h6">{strings.label.ingredients}</Typography>

                    <Table>
                        <colgroup>
                            <col width="5%" />
                            <col width="20%" />
                            <col width="10%" />
                            <col width="5%" />
                            <col width="60%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>{strings.column.id}</TableCell>
                                <TableCell>{strings.column.name}</TableCell>
                                <TableCell>{strings.column.influence}</TableCell>
                                <TableCell>{strings.column.recommend}</TableCell>
                                <TableCell>{strings.column.details}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {item.ingredients.map((dietIngredient, index) => (
                                <TableRow key={index} hover={true}>
                                    <TableCell>{dietIngredient.ingredient.id}</TableCell>
                                    <TableCell>{dietIngredient.ingredient.name}</TableCell>
                                    <TableCell>{dietIngredientInfluenceText(dietIngredient.influence)}</TableCell>
                                    <TableCell>{this.textForRecommendedType(dietIngredient.recommend)}</TableCell>
                                    <TableCell className={classes.multilineText}>{dietIngredient.details}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        );
    }
    // #endregion

    // #region Private
    private load = async (): Promise<void> => {
        try {
            const item = await API.diets.detail(parseInt(this.props.match.params.dietId, 10));
            this.setState({ loading: false, item });
        } catch (error) {
            this.setState({ loading: false });
        }
    };

    private textForRecommendedType = (type: DietIngredientRecommendedType): React.ReactNode => {
        switch (type) {
            case 1:
                return <Typography style={{ color: '#88DE31' }}>Tak - Zielony</Typography>;
            case 2:
                return <Typography style={{ color: '#FF7E7E' }}>Tak - Czerwony</Typography>;
            case 3:
                return <Typography style={{ color: '#171D33' }}>Tak - Czarny</Typography>;
            default:
                return <Typography>Nie</Typography>;
        }
    };
    // #endregion
}

export default withAppCanvas(withStyles(styles)(DietDetailsPage));
