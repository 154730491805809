import {
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Tooltip,
} from '@material-ui/core';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import RefreshIcon from '@material-ui/icons/Refresh';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { API } from '../../api';
import { IngredientItem } from '../../api/response';
import withAppCanvas, { AppCanvasComponentProps } from '../../components/AppCanvas';
import { MenuItemType } from '../../model/menuItems';
import { routesDetails } from '../../routes/routesDetails';
import IngredientTableRow from './IngredientTableRow';

// #region UI
const strings = {
    button: {
        refresh: 'Odśwież',
    },
    column: {
        icon: 'Ikona',
        id: 'Id',
        name: 'Nazwa',
        type: 'Typ',
    },
};
// #endregion

// #region Props & State
interface ComponentProps extends RouteComponentProps, AppCanvasComponentProps {}

interface ComponentState {
    items: IngredientItem[];
    loading: boolean;
    page: number;
    rowsPerPage: number;
}

const initialState: ComponentState = {
    items: [],
    loading: true,
    page: 0,
    rowsPerPage: 10,
};
// #endregion

class IngredientsPage extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = initialState;

        const { dispatchChangeMenuItem, dispatchSetTitle } = this.props;
        dispatchChangeMenuItem(MenuItemType.Ingredients);
        dispatchSetTitle(MenuItemType.Ingredients);

        this.load();
    }

    // #region Lifecycle
    public render(): React.ReactNode {
        const { loading, items, page, rowsPerPage } = this.state;

        if (loading) {
            return (
                <Grid container={true} justify="center">
                    <CircularProgress />
                </Grid>
            );
        }

        return (
            <Paper elevation={2}>
                <Toolbar>
                    <Tooltip title={strings.button.refresh}>
                        <IconButton onClick={this.onRefreshClick}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                <Table size="small">
                    <colgroup>
                        <col width="10%" />
                        <col width="10%" />
                        <col width="20%" />
                        <col width="60%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>{strings.column.id}</TableCell>
                            <TableCell>{strings.column.icon}</TableCell>
                            <TableCell>{strings.column.type}</TableCell>
                            <TableCell>{strings.column.name}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(this.renderItem)}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 50]}
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.onPageChange}
                    labelDisplayedRows={this.labelDisplayerRows}
                    labelRowsPerPage={'Wierszy na stronie:'}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                />
            </Paper>
        );
    }
    // #endregion

    // #region Private
    private load = async (): Promise<void> => {
        try {
            const items = await API.ingredients.list();
            this.setState({ loading: false, items });
        } catch (error) {
            this.setState({ loading: false });
        }
    };

    private renderItem = (item: IngredientItem): React.ReactNode => {
        return <IngredientTableRow key={item.id} item={item} onItemClick={this.onItemClick} />;
    };

    private onItemClick = (item: IngredientItem): void => {
        const { history } = this.props;
        history.push(routesDetails.authenticated.ingredient.to(item.id));
    };

    private onRefreshClick = async (): Promise<void> => {
        this.setState({ loading: true, items: [] });
        await this.load();
    };

    // tslint:disable-next-line
    private onPageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, page: number): void => {
        this.setState({ page });
    };

    private onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    };

    private labelDisplayerRows = (paginationInfo: LabelDisplayedRowsArgs): React.ReactNode => {
        const { from, to, count } = paginationInfo;
        return `${from}-${to} z ${count}`;
    };
}

export default withAppCanvas(IngredientsPage);
