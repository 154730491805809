import {
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { API } from '../../api';
import { Category } from '../../api/response';
import withAppCanvas, { AppCanvasComponentProps } from '../../components/AppCanvas';
import { MenuItemType } from '../../model/menuItems';
import CategoriesTableRow from './CategoriesTableRow';

// #region UI
const strings = {
    button: {
        refresh: 'Odśwież',
    },
    column: {
        id: 'Id',
        isDangerous: 'Czy niebezpieczna',
        name: 'Nazwa',
        orderPriority: 'Priorytet sortowania',
    },
};
// #endregion

// #region Props & State
interface ComponentProps extends RouteComponentProps, AppCanvasComponentProps {}

interface ComponentState {
    items: Category[];
    loading: boolean;
}

const initialState: ComponentState = {
    items: [],
    loading: true,
};
// #endregion

class CategoriesPage extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = initialState;

        const { dispatchChangeMenuItem, dispatchSetTitle } = this.props;
        dispatchChangeMenuItem(MenuItemType.Categories);
        dispatchSetTitle(MenuItemType.Categories);

        this.load();
    }

    // #region Lifecycle
    public render(): React.ReactNode {
        const { loading, items } = this.state;

        if (loading) {
            return (
                <Grid container={true} justify="center">
                    <CircularProgress />
                </Grid>
            );
        }

        return (
            <Paper elevation={2}>
                <Toolbar>
                    <Tooltip title={strings.button.refresh}>
                        <IconButton onClick={this.onRefreshClick}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                <Table>
                    <colgroup>
                        <col width="10%" />
                        <col width="50%" />
                        <col width="20%" />
                        <col width="20%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>{strings.column.id}</TableCell>
                            <TableCell>{strings.column.name}</TableCell>
                            <TableCell>{strings.column.orderPriority}</TableCell>
                            <TableCell>{strings.column.isDangerous}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{items.map(this.renderItem)}</TableBody>
                </Table>
            </Paper>
        );
    }
    // #endregion

    // #region Private
    private load = async (): Promise<void> => {
        try {
            const items = await API.categories.list();
            this.setState({ loading: false, items });
        } catch (error) {
            this.setState({ loading: false });
        }
    };

    private renderItem = (item: Category): React.ReactNode => {
        return <CategoriesTableRow key={item.id} item={item} onItemClick={this.onItemClick} />;
    };

    // tslint:disable-next-line
    private onItemClick = (_: Category): void => {};

    private onRefreshClick = async (): Promise<void> => {
        this.setState({ loading: true, items: [] });
        await this.load();
    };
    // #endregion
}

export default withAppCanvas(CategoriesPage);
