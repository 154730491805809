import { TableCell, TableRow } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';
import { API } from '../../api';
import { Product, ProductIngredient } from '../../api/response';

// #region UI
const styles = () =>
    createStyles({
        photo: {
            height: 36,
            objectFit: 'cover',
            width: 36,
        },
    });
// #endregion

// #region Props & State
interface ComponentProps extends WithStyles<typeof styles> {
    item: Product;
}
// #endregion

class ProductTableRow extends React.Component<ComponentProps> {
    // #region Lifecycle
    public render(): React.ReactNode {
        const { classes, item } = this.props;

        return (
            <TableRow hover={true}>
                <TableCell>
                    {!!item.photoFilename && (
                        <img
                            alt={item.name}
                            className={classes.photo}
                            src={API.products.photoUrl(item.id, 144, item.photoFilename)}
                        />
                    )}
                </TableCell>
                <TableCell>{item.gtin}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                    {!!item.packSize && item.packSize}
                    {!item.packSize && '-'}
                </TableCell>
                <TableCell>
                    {item.ingredients.length > 0 && this.ingredients(item.ingredients)}
                    {item.ingredients.length === 0 && '-'}
                </TableCell>
            </TableRow>
        );
    }
    // #endregion

    // #region Private
    private ingredients = (ingredients: ProductIngredient[]): React.ReactNode => {
        return (
            <ul>
                {ingredients.map((ingredient, index) => (
                    <li key={index}>{ingredient.normalizedIngredient.originalName}</li>
                ))}
            </ul>
        );
    };
    // #endregion
}

export default withStyles(styles)(ProductTableRow);
