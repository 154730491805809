import { Action, ActionCreator } from 'redux';
import { MessageActionType } from './actionTypes';

// #region ShowMessage
export interface ShowMessageAction extends Action<MessageActionType.ShowMessage> {
    message: string;
}

export const showMessage: ActionCreator<ShowMessageAction> = (message: string): ShowMessageAction => ({
    message,
    type: MessageActionType.ShowMessage,
});
// #endregion

// #region HideMessage
export interface HideMessageAction extends Action<MessageActionType.HideMessage> {}

export const hideMessage: ActionCreator<HideMessageAction> = (): HideMessageAction => ({
    type: MessageActionType.HideMessage,
});
// #endregion

export type MessageAction = ShowMessageAction | HideMessageAction;
