export class ArticleData {
    constructor(
        readonly title: string,
        readonly lead: string,
        readonly content: string,
        readonly photoFilename: string | null,
        readonly detailPhotoFilename: string | null,
        readonly publicationDate: string | null,
        readonly sendPushNotification: boolean
    ) {}
}
