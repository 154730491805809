import { Reducer } from 'redux';
import { MessageAction, MessageActionType } from '../actions';

type ReducerState = string | null;
type ReducerAction = MessageAction;

const initialState: ReducerState = null;

export const message: Reducer<ReducerState, ReducerAction> = (
    state: ReducerState = initialState,
    action: ReducerAction
): ReducerState => {
    switch (action.type) {
        case MessageActionType.ShowMessage:
            return action.message;
        case MessageActionType.HideMessage:
            return null;
        default:
            return state;
    }
};
