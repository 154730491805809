import { Auth } from '.';

enum LocalStorageKey {
    Auth = 'Auth',
}

class LocalStore {
    public clearStore = (): void => {
        try {
            localStorage.clear();
        } catch (error) {
            // Ignore
        }
    };

    public storeAuth = (auth: Auth): void => {
        try {
            localStorage.setItem(LocalStorageKey.Auth, JSON.stringify(auth));
        } catch (error) {
            // Ignore
        }
    };

    public removeAuth = (): void => {
        try {
            localStorage.removeItem(LocalStorageKey.Auth);
        } catch (error) {
            // Ignore
        }
    };

    public auth = (): Auth | null => {
        try {
            const json: string | null = localStorage.getItem(LocalStorageKey.Auth);
            if (json) {
                return JSON.parse(json);
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };
}

const localStore = new LocalStore();

export default localStore;
