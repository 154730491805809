import { CssBaseline, Theme } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { ActionCreator, ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import { MenuItemType } from '../model/menuItems';
import { StoreState } from '../store';
import {
    changeMenuItem,
    ChangeMenuItemAction,
    clearMenuItem,
    ClearMenuItemAction,
    clearTitle,
    ClearTitleAction,
    setTitle,
    SetTitleAction,
} from '../store/actions';
import DefaultAppBar from './DefaultAppBar';
import DefaultDrawer from './DefaultDrawer';

// #region UI
const styles = (theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        root: {
            display: 'flex',
        },
        toolbar: theme.mixins.toolbar,
    });
// #endregion

// #region Props & State
interface StateProps {}
interface DispatchProps {
    dispatchChangeMenuItem: (current: MenuItemType) => void;
    dispatchClearMenuItem: () => void;
    dispatchSetTitle: (title: string) => void;
    dispatchClearTitle: () => void;
}
interface OwnProps {}
export type AppCanvasComponentProps = StateProps & DispatchProps & OwnProps;
type ComponentProps = AppCanvasComponentProps & WithStyles<typeof styles>;

interface ComponentState {}
// #endregion

// #region Connect
interface ActionDispatches {
    dispatchChangeMenuItem: ActionCreator<ChangeMenuItemAction>;
    dispatchClearMenuItem: ActionCreator<ClearMenuItemAction>;
    dispatchSetTitle: ActionCreator<SetTitleAction>;
    dispatchClearTitle: ActionCreator<ClearTitleAction>;
}

interface ActionCreators
    extends ActionCreatorsMapObject<ChangeMenuItemAction | ClearMenuItemAction | SetTitleAction | ClearTitleAction> {
    dispatchChangeMenuItem: ActionCreator<ChangeMenuItemAction>;
    dispatchClearMenuItem: ActionCreator<ClearMenuItemAction>;
    dispatchSetTitle: ActionCreator<SetTitleAction>;
    dispatchClearTitle: ActionCreator<ClearTitleAction>;
}

const mapDispatchToProps = (dispatch: Dispatch): ActionDispatches =>
    bindActionCreators<ActionCreators>(
        {
            dispatchChangeMenuItem: changeMenuItem,
            dispatchClearMenuItem: clearMenuItem,
            dispatchClearTitle: clearTitle,
            dispatchSetTitle: setTitle,
        },
        dispatch
    );
// #endregion

const withAppCanvas = <P extends AppCanvasComponentProps>(Component: React.ComponentType<P>) => {
    const appCanvas = class AppCanvas extends React.Component<ComponentProps, ComponentState> {
        public render(): React.ReactNode {
            const { classes, ...restProps } = this.props;

            return (
                <div className={classes.root}>
                    <CssBaseline />
                    <DefaultAppBar />
                    <DefaultDrawer />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Component {...restProps as P} />
                    </main>
                </div>
            );
        }
    };

    return connect<StateProps, DispatchProps, OwnProps, StoreState>(
        null,
        mapDispatchToProps
    )(withStyles(styles)(appCanvas));
};

export default withAppCanvas;
