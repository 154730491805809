import { Grid, Typography } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';
import logo from '../../assets/logo.png';

// #region UI
const strings = {
    text: {
        appName: 'Wiesz Co Jesz',
    },
};

const styles = createStyles({
    container: {
        paddingBottom: 16,
        paddingTop: 128,
    },
    logo: {
        height: 200,
        width: 200,
    },
    title: {
        fontSize: 72,
    },
});
// #endregion

class LoginLogo extends React.Component<WithStyles<typeof styles>> {
    public render(): React.ReactNode {
        const { classes } = this.props;

        return (
            <Grid
                container={true}
                spacing={0}
                className={classes.container}
                alignItems="center"
                justify="center"
                direction="column"
            >
                <Grid item={true}>
                    <img className={classes.logo} src={logo} alt={strings.text.appName} />
                </Grid>
                <Grid item={true}>
                    <Typography className={classes.title}>{strings.text.appName}</Typography>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(LoginLogo);
