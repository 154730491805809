import { AnyAction, Reducer } from 'redux';
import { ActionStateType } from '../actions';

type ReducerState = number;
type ReducerAction = AnyAction;

const initialState: number = 0;

export const ongoingActions: Reducer<ReducerState, ReducerAction> = (
    state: ReducerState = initialState,
    action: ReducerAction
): ReducerState => {
    switch (action.type) {
        case ActionStateType.SetInProgressState:
            return state + 1;
        case ActionStateType.SetSuccessState:
        case ActionStateType.SetFailState:
            return Math.max(0, state - 1);
        default:
            return state;
    }
};
