import React from 'react';
import { RouteComponentProps } from 'react-router';
import withAppCanvas, { AppCanvasComponentProps } from '../../components/AppCanvas';

// #region Props & State
interface ComponentProps extends RouteComponentProps, AppCanvasComponentProps {}
// #endregion

class HomePage extends React.Component<ComponentProps> {
    constructor(props: ComponentProps) {
        super(props);

        const { dispatchClearMenuItem, dispatchClearTitle } = this.props;
        dispatchClearMenuItem();
        dispatchClearTitle();
    }

    public render(): React.ReactNode {
        return null;
    }
}

export default withAppCanvas(HomePage);
