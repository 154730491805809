import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Category } from '../../api/response';

// #region UI
const strings = {
    text: {
        no: 'Nie',
        yes: 'Tak',
    },
};
// #endregion

// #region Props & State
interface ComponentProps {
    item: Category;
    onItemClick: (item: Category) => void;
}
// #endregion

class CategoriesTableRow extends React.Component<ComponentProps> {
    // #region Lifecycle
    public shouldComponentUpdate(nextProps: Readonly<ComponentProps>): boolean {
        const currentItem = this.props.item;
        const nextItem = nextProps.item;

        return (
            currentItem.id !== nextItem.id ||
            currentItem.name !== nextItem.name ||
            currentItem.orderPriority !== nextItem.orderPriority ||
            currentItem.isDangerous !== nextItem.isDangerous
        );
    }

    public render(): React.ReactNode {
        const { item } = this.props;

        return (
            <TableRow hover={true} onClick={this.onItemClick}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.orderPriority}</TableCell>
                <TableCell>{item.isDangerous ? strings.text.yes : strings.text.no}</TableCell>
            </TableRow>
        );
    }
    // #endregion

    // #region Private
    private onItemClick = (): void => {
        const { item, onItemClick } = this.props;
        onItemClick(item);
    };
    // #endregion
}

export default CategoriesTableRow;
