import {
    Button,
    FormControl,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputLabel,
    Snackbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import _ from 'lodash';
import React from 'react';
import Validator from 'validator';
import { nameofFactory } from '../../utils/nameofFactory';

// #region UI
const strings = {
    button: {
        login: 'Zaloguj',
    },
    error: {
        loginFailed: 'Logowanie zakończone niepowodzeniem',
        passwordIsRequired: 'Hasło jest wymagane',
        userNameIsRequired: 'Login jest wymagany',
    },
    label: {
        password: 'Hasło',
        userName: 'Login',
    },
    placeholder: {
        password: 'Podaj hasło',
        userName: 'Podaj login',
    },
};

const styles = createStyles({
    container: {
        width: 310,
    },
    loginButton: {
        fontWeight: 400,
        letterSpacing: 1,
    },
    loginButtonContainer: {
        marginTop: 16,
    },
});
// #endregion

// #region Form
enum FormFieldNames {
    UserName = 'userName',
    Password = 'password',
}

interface FormFields {
    userName: string;
    password: string;
}

interface FormErrors {
    userName?: string;
    password?: string;
    form?: string;
}
// #endregion

// #region Props & State
interface OwnProps {
    readonly submit: (userName: string, password: string) => Promise<void>;
}

type ComponentProps = WithStyles<typeof styles> & OwnProps;

interface ComponentState {
    fields: FormFields;
    errors: FormErrors;
    loading: boolean;
}

const initialState: ComponentState = {
    errors: {},
    fields: {
        password: '',
        userName: '',
    },
    loading: false,
};
// #endregion

class LoginForm extends React.Component<ComponentProps, ComponentState> {
    private usernameInput: React.RefObject<HTMLInputElement>;
    private passwordInput: React.RefObject<HTMLInputElement>;

    constructor(props: ComponentProps) {
        super(props);

        this.usernameInput = React.createRef<HTMLInputElement>();
        this.passwordInput = React.createRef<HTMLInputElement>();
        this.state = initialState;
    }

    public render(): React.ReactNode {
        const { fields, errors, loading } = this.state;
        const { classes } = this.props;

        return (
            <Grid container={true} justify="center" spacing={0}>
                <Grid item={true} className={classes.container}>
                    <Snackbar
                        anchorOrigin={{
                            horizontal: 'center',
                            vertical: 'bottom',
                        }}
                        open={!!errors.form}
                        autoHideDuration={6000}
                        onClose={this.onFormErrorClose}
                        message={<span>{errors.form}</span>}
                        action={[
                            <IconButton key="close" color="inherit" onClick={this.onFormErrorClose}>
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />

                    <form onSubmit={this.onFormSubmit}>
                        <FormGroup>
                            <FormControl error={!!errors.userName} margin="none">
                                <InputLabel>{strings.label.userName}</InputLabel>
                                <Input
                                    id={FormFieldNames.UserName}
                                    inputRef={this.usernameInput}
                                    name={FormFieldNames.UserName}
                                    onChange={this.onFieldChange}
                                    placeholder={strings.placeholder.userName}
                                    type="text"
                                    value={fields.userName}
                                />
                                <FormHelperText>{errors.userName}</FormHelperText>
                            </FormControl>

                            <FormControl error={!!errors.password} margin="normal">
                                <InputLabel>{strings.label.password}</InputLabel>
                                <Input
                                    id={FormFieldNames.Password}
                                    inputRef={this.passwordInput}
                                    name={FormFieldNames.Password}
                                    onChange={this.onFieldChange}
                                    placeholder={strings.placeholder.password}
                                    type="password"
                                    value={fields.password}
                                />
                                <FormHelperText>{errors.password}</FormHelperText>
                            </FormControl>
                        </FormGroup>

                        <Grid container={true} spacing={2} className={classes.loginButtonContainer} justify="flex-end">
                            <Grid item={true}>
                                <Button
                                    classes={{ label: classes.loginButton }}
                                    color="primary"
                                    disabled={loading}
                                    type="submit"
                                    variant="contained"
                                >
                                    {strings.button.login}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        );
    }

    // #region Private
    private validateFormFields(fields: FormFields): FormErrors {
        const errors: FormErrors = {};

        if (Validator.isEmpty(fields.userName)) {
            errors.userName = strings.error.userNameIsRequired;
        }

        if (Validator.isEmpty(fields.password)) {
            errors.password = strings.error.passwordIsRequired;
        }

        return errors;
    }

    private onFieldChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            fields: { ...this.state.fields, [event.target.name]: event.target.value },
        });
    };

    private onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        const errors: FormErrors = this.validateFormFields(this.state.fields);
        this.setState({ errors });

        if (_.isEmpty(errors)) {
            const { userName, password } = this.state.fields;
            this.setState({ loading: true });

            try {
                await this.props.submit(userName, password);
            } catch (error) {
                if (error === null) {
                    this.setState({
                        loading: false,
                    });
                } else {
                    this.setState({
                        errors: { form: strings.error.loginFailed },
                        loading: false,
                    });
                }
            }
        } else if (!!errors.userName && this.usernameInput.current !== null) {
            this.usernameInput.current.focus();
        } else if (!!errors.password && this.passwordInput.current !== null) {
            this.passwordInput.current.focus();
        }
    };

    private onFormErrorClose = (): void => {
        const nameof = nameofFactory<FormErrors>();
        const errors: FormErrors = _.omit(this.state.errors, nameof('form'));
        this.setState({ errors });
    };
    // #endregion
}

export default withStyles(styles)(LoginForm);
