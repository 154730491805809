import { Reducer } from 'redux';
import { ErrorAction, ErrorActionType } from '../actions';

type ReducerState = Error | null;
type ReducerAction = ErrorAction;

const initialState: ReducerState = null;

export const error: Reducer<ReducerState, ReducerAction> = (
    state: ReducerState = initialState,
    action: ReducerAction
): ReducerState => {
    switch (action.type) {
        case ErrorActionType.ShowError:
            return action.error;
        case ErrorActionType.ClearError:
            return null;
        default:
            return state;
    }
};
