import qs from 'querystring';
import client from './client';
import { ArticleData } from './request/ArticleData';
import { NotificationData } from './request/NotificationData';
import { SettingData } from './request/SettingData';
import * as Model from './response';

const authToken = async (username: string, password: string): Promise<Model.AuthResponse> => {
    const params = {
        password,
        username,
    };
    const response = await client.post<Model.AuthResponse>('oauth2/token', qs.stringify(params));
    return response.data;
};

// #region Show content
const categoriesList = async (): Promise<Model.Category[]> => {
    const response = await client.get<Model.Category[]>('categories');
    return response.data;
};

const ingredientsList = async (): Promise<Model.IngredientItem[]> => {
    const response = await client.get<Model.IngredientItem[]>('ingredients');
    return response.data;
};

const ingredientDetails = async (ingredientId: number): Promise<Model.Ingredient> => {
    const response = await client.get<Model.Ingredient>(`ingredients/${ingredientId}`);
    return response.data;
};

const ingredientPhotoUrl = (ingredientId: number, photoFilename: string): string => {
    return `${process.env.REACT_APP_API_URL}ingredients/${ingredientId}/photos/${photoFilename}.png`;
};

const dietsList = async (): Promise<Model.DietItem[]> => {
    const response = await client.get<Model.DietItem[]>('diets');
    return response.data;
};

const dietDetails = async (dietId: number): Promise<Model.Diet> => {
    const response = await client.get<Model.Diet>(`diets/${dietId}`);
    return response.data;
};
// #endregion

// #region Import
const importExcel = async (excel: File): Promise<void> => {
    const data = new FormData();
    data.append('excel', excel);
    await client.post<void>('import/excel', data, { timeout: 0 });
};

const importPhotos = async (photos: File[]): Promise<void> => {
    const data = new FormData();
    photos.forEach((photo) => {
        data.append('photo[]', photo);
    });
    await client.post<void>('import/photos', data);
};
// #endregion

// #region Settings
const settingsList = async (): Promise<Model.Setting[]> => {
    const response = await client.get<Model.Setting[]>('settings');
    return response.data;
};

const settingsUpdate = async (key: string, value: string): Promise<Model.Setting> => {
    const data = new SettingData(value);
    const response = await client.post<Model.Setting>(`settings/${key}`, data);
    return response.data;
};
// #endregion

// #region Versions
const versionsList = async (): Promise<Model.Version[]> => {
    const response = await client.get<Model.Version[]>('versions');
    return response.data;
};

const versionsCreate = async (): Promise<Model.Version> => {
    const response = await client.post<Model.Version>('versions');
    return response.data;
};

const versionsDelete = async (versionId: number): Promise<void> => {
    const response = await client.delete(`versions/${versionId}`);
    return response.data;
};
// #endregion

// #region Notifications
const notificationsList = async (): Promise<Model.NotificationItem[]> => {
    const response = await client.get<Model.NotificationItem[]>('notifications');
    return response.data;
};

const notificationsDetails = async (notificationId: number): Promise<Model.NotificationDetails> => {
    const response = await client.get<Model.NotificationDetails>(`notifications/${notificationId}`);
    return response.data;
};

const notificationsAdd = async (data: NotificationData): Promise<Model.NotificationDetails> => {
    const response = await client.post<Model.NotificationDetails>('notifications', data);
    return response.data;
};

const notificationsEdit = async (
    notificationId: number,
    data: NotificationData
): Promise<Model.NotificationDetails> => {
    const response = await client.post<Model.NotificationDetails>(`notifications/${notificationId}`, data);
    return response.data;
};

const notificationsDelete = async (notificationId: number): Promise<void> => {
    const response = await client.delete(`notifications/${notificationId}`);
    return response.data;
};
// #endregion

// #region Articles
const articlesList = async (): Promise<Model.ArticleItem[]> => {
    const response = await client.get<Model.ArticleItem[]>('articles');
    return response.data;
};

const articlesDetails = async (articleId: number): Promise<Model.ArticleDetails> => {
    const response = await client.get<Model.ArticleDetails>(`articles/${articleId}`);
    return response.data;
};

const articlesAddPhoto = async (photo: File): Promise<Model.Photo> => {
    const data = new FormData();
    data.append('photo', photo);
    const response = await client.post<Model.Photo>('articles/photos', data);
    return response.data;
};

const articlesAdd = async (data: ArticleData): Promise<Model.ArticleDetails> => {
    const response = await client.post<Model.ArticleDetails>('articles', data);
    return response.data;
};

const articlesPhoto = (photoFilename: string): string => {
    return `${process.env.REACT_APP_API_URL}articles/photos/original/${photoFilename}`;
};

const articlesEdit = async (articleId: number, data: ArticleData): Promise<Model.ArticleDetails> => {
    const response = await client.post<Model.ArticleDetails>(`articles/${articleId}`, data);
    return response.data;
};

const articlesDelete = async (articleId: number): Promise<void> => {
    const response = await client.delete(`articles/${articleId}`);
    return response.data;
};
// #endregion

// #region Products
const excelProductsList = async (): Promise<Model.Product[]> => {
    const response = await client.get<Model.Product[]>('import/products');
    return response.data;
};

const importProductsExcel = async (excel: File): Promise<void> => {
    const data = new FormData();
    data.append('excel', excel);
    await client.post<void>('import/products/excel', data, { timeout: 0 });
};

const importProductsPhotos = async (photos: File[]): Promise<void> => {
    const data = new FormData();
    photos.forEach((photo) => {
        data.append('photo[]', photo);
    });
    await client.post<void>('import/products/photos', data);
};

const productsPhotoUrl = (productId: number, size: number, photoFilename: string): string => {
    return `${process.env.REACT_APP_API_URL}products/${productId}/photos/${size}/${photoFilename}`;
};
// #endregion

// #region Affiliate
const affiliateLinksImportExcel = async (excel: File): Promise<void> => {
    const data = new FormData();
    data.append('excel', excel);
    await client.post<void>('affiliate/import', data, { timeout: 0 });
};

const affiliateLinksSetBuyButtonPhoto = async (photo: File): Promise<void> => {
    const data = new FormData();
    data.append('photo', photo);
    await client.post<void>('affiliate/button', data);
};

const affiliateLinksClear = async (): Promise<void> => {
    await client.delete('affiliate');
};
// #endregion

export const API = {
    affiliate: {
        import: affiliateLinksImportExcel,
        setBuyButtonPhoto: affiliateLinksSetBuyButtonPhoto,
        clear: affiliateLinksClear,
    },
    articles: {
        add: articlesAdd,
        addPhoto: articlesAddPhoto,
        delete: articlesDelete,
        details: articlesDetails,
        edit: articlesEdit,
        list: articlesList,
        photo: articlesPhoto,
    },
    auth: {
        token: authToken,
    },
    categories: {
        list: categoriesList,
    },
    diets: {
        detail: dietDetails,
        list: dietsList,
    },
    import: {
        excel: importExcel,
        photos: importPhotos,
    },
    ingredients: {
        detail: ingredientDetails,
        list: ingredientsList,
        photoUrl: ingredientPhotoUrl,
    },
    notifications: {
        add: notificationsAdd,
        delete: notificationsDelete,
        details: notificationsDetails,
        edit: notificationsEdit,
        list: notificationsList,
    },
    products: {
        excel: importProductsExcel,
        list: excelProductsList,
        photoUrl: productsPhotoUrl,
        photos: importProductsPhotos,
    },
    settings: {
        list: settingsList,
        update: settingsUpdate,
    },
    versions: {
        create: versionsCreate,
        delete: versionsDelete,
        list: versionsList,
    },
};
