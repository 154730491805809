import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';
import { Store } from 'redux';
import { StoreState } from '../store';
import { showError } from '../store/actions';
import { ErrorResponse } from './response';

const strings: { [keys: string]: string } = {
    error401: 'Twoja sesja wygasła, zostaniesz wylogowany',
    error403: 'Nie masz uprawnień do dostępu do tego zasobu',
    error404: 'Wybrany zasób nie istnieje',
    error5xx: 'Coś poszło nie tak, proszę spróbuj ponownie później',
    errorNetwork: 'Brak połączenia internetowego',
    errorTimeout: 'Proszę spróbować ponownie',
};

const client: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { Accept: 'application/json' },
    timeout: 60000,
});

export const configureClient = (store: Store<StoreState>): void => {
    client.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            const state = store.getState();
            if (state.auth !== null) {
                const { token } = state.auth;
                config.headers.Authorization = 'Bearer ' + token;
            }

            config.headers['Cache-Control'] = 'no-cache';

            return config;
        },
        (error: AxiosError) => {
            return Promise.reject(error);
        }
    );

    client.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: AxiosError) => {
            const state = store.getState();
            let reason: ErrorResponse | null = null;

            if (error.message === 'Network Error') {
                store.dispatch(showError(strings.errorNetwork, '500'));
            } else if (_.startsWith(error.message, 'timeout')) {
                store.dispatch(showError(strings.errorTimeout, '500'));
            } else if (error.response !== undefined) {
                const { status } = error.response;
                let message: string | undefined | null = error.response.data.message;
                if (message === undefined) {
                    message = null;
                }

                if (status === 400) {
                    reason = { error: 400, message };
                } else if (status === 401 && state.auth !== null) {
                    store.dispatch(showError(strings.error401, '401'));
                } else if (status === 401) {
                    reason = { error: 401, message };
                } else if (status === 403) {
                    store.dispatch(showError(strings.error403, '403'));
                } else if (status === 404) {
                    store.dispatch(showError(strings.error404, '404'));
                } else if (status >= 500) {
                    store.dispatch(showError(strings.error5xx, '500'));
                }
            }

            return Promise.reject(reason);
        }
    );
};

export default client;
