import { Divider, Drawer, List, Theme } from '@material-ui/core';
import CategoryIcon from '@material-ui/icons/Category';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import FormatColorTextIcon from '@material-ui/icons/FormatColorText';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import TuneIcon from '@material-ui/icons/Tune';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ActionCreator, ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import { MenuItemType } from '../model/menuItems';
import { routesDetails } from '../routes/routesDetails';
import { StoreState } from '../store';
import { changeMenuItem, ChangeMenuItemAction, setTitle, SetTitleAction } from '../store/actions';
import DrawerListItem from './DrawerListItem';

// #region UI
export const drawerWidth = 260;

const styles = (theme: Theme) =>
    createStyles({
        drawer: {
            flexShrink: 0,
            width: drawerWidth,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        toolbar: theme.mixins.toolbar,
    });
// #endregion

// #region Props & State
interface StateProps {
    currentMenuItem: MenuItemType | null;
}
interface DispatchProps {
    dispatchChangeMenuItem: (current: MenuItemType) => void;
    dispatchSetTitle: (title: string) => void;
}
interface OwnProps extends RouteComponentProps {}
type ComponentProps = WithStyles<typeof styles> & StateProps & DispatchProps & OwnProps;

interface ComponentState {}
// #endregion

class DefaultDrawer extends React.Component<ComponentProps, ComponentState> {
    // #region Lifecycle
    public render(): React.ReactNode {
        const { classes, currentMenuItem } = this.props;

        const groups = [
            [
                {
                    icon: CategoryIcon,
                    menuItem: MenuItemType.Categories,
                    path: routesDetails.authenticated.categories.path,
                },
                {
                    icon: FastfoodIcon,
                    menuItem: MenuItemType.Ingredients,
                    path: routesDetails.authenticated.ingredients.path,
                },
                {
                    icon: RestaurantIcon,
                    menuItem: MenuItemType.Diets,
                    path: routesDetails.authenticated.diets.path,
                },
            ],
            [
                {
                    icon: LocalGroceryStoreIcon,
                    menuItem: MenuItemType.Products,
                    path: routesDetails.authenticated.products.path,
                },
                {
                    icon: CloudUploadIcon,
                    menuItem: MenuItemType.ProductsImport,
                    path: routesDetails.authenticated.importProducts.path,
                },
                {
                    icon: MonetizationOnIcon,
                    menuItem: MenuItemType.AffiliateLinks,
                    path: routesDetails.authenticated.affiliate.path,
                },
            ],
            [
                {
                    icon: FormatColorTextIcon,
                    menuItem: MenuItemType.Articles,
                    path: routesDetails.authenticated.articles.path,
                },
                {
                    icon: NotificationsActiveIcon,
                    menuItem: MenuItemType.Notifications,
                    path: routesDetails.authenticated.notifications.path,
                },
            ],
            [
                {
                    icon: TuneIcon,
                    menuItem: MenuItemType.Settings,
                    path: routesDetails.authenticated.settings.path,
                },
                {
                    icon: FormatListNumberedIcon,
                    menuItem: MenuItemType.Versions,
                    path: routesDetails.authenticated.versions.path,
                },
                {
                    icon: CloudUploadIcon,
                    menuItem: MenuItemType.Import,
                    path: routesDetails.authenticated.import.path,
                },
            ],
        ];

        return (
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.toolbar} />
                {groups.map((group, groupIndex) => (
                    <React.Fragment key={groupIndex}>
                        <List>
                            {group.map((item, itemIndex) => (
                                <DrawerListItem
                                    key={itemIndex}
                                    onChange={this.onMenuItemChange}
                                    menuItem={item.menuItem}
                                    isSelected={currentMenuItem === item.menuItem}
                                    icon={item.icon}
                                    path={item.path}
                                />
                            ))}
                        </List>
                        {groupIndex < groups.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </Drawer>
        );
    }
    // #endregion

    // #region Private
    private onMenuItemChange = (menuItem: MenuItemType, path: string): void => {
        const { dispatchChangeMenuItem, dispatchSetTitle, history } = this.props;
        dispatchChangeMenuItem(menuItem);
        dispatchSetTitle(menuItem);

        history.push(path);
    };
    // #endregion
}

// #region Connect
interface ActionDispatches {
    dispatchChangeMenuItem: ActionCreator<ChangeMenuItemAction>;
    dispatchSetTitle: ActionCreator<SetTitleAction>;
}

interface ActionCreators extends ActionCreatorsMapObject<ChangeMenuItemAction | SetTitleAction> {
    dispatchChangeMenuItem: ActionCreator<ChangeMenuItemAction>;
    dispatchSetTitle: ActionCreator<SetTitleAction>;
}

const mapDispatchToProps = (dispatch: Dispatch): ActionDispatches =>
    bindActionCreators<ActionCreators>(
        {
            dispatchChangeMenuItem: changeMenuItem,
            dispatchSetTitle: setTitle,
        },
        dispatch
    );

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, StoreState> = (state: StoreState): StateProps => ({
    currentMenuItem: state.currentMenuItem,
});

export default withRouter(
    connect<StateProps, DispatchProps, OwnProps, StoreState>(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(DefaultDrawer))
);
// #endregion
