import { TableCell, TableRow, Input, IconButton } from '@material-ui/core';
import React from 'react';
import { Setting } from '../../api/response';
import SaveIcon from '@material-ui/icons/Save';

// #region Props & State
interface ComponentProps {
    item: Setting;
    description: string;
    onItemUpdate: (key: string, value: string) => void;
}

interface ComponentState {
    value: number;
}

const initialState = (value: number): ComponentState => ({
    value: isNaN(value) ? 0 : value,
});
// #endregion

class SettingInputTableRow extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        const { item } = props;
        this.state = initialState(parseInt(item.value));
    }

    // #region Lifecycle
    public render(): React.ReactNode {
        const { value } = this.state;
        const { item, description } = this.props;

        const save = parseInt(item.value) !== value;

        return (
            <TableRow hover={true}>
                <TableCell>{description}</TableCell>
                <TableCell>
                    <Input
                        type="number"
                        style={{ width: 50, marginLeft: 12, paddingLeft: 2 }}
                        value={value}
                        onChange={this.onItemChange}
                    />

                    <IconButton color="primary" component="span" disabled={!save} onClick={this.onSaveClick}>
                        <SaveIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }
    // #endregion

    // #region Private
    // tslint:disable-next-line
    private onItemChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = parseInt(event.target.value);

        this.setState({ value: isNaN(value) ? 0 : value });
    };

    private onSaveClick = (): void => {
        const { value } = this.state;
        const { item, onItemUpdate } = this.props;

        onItemUpdate(item.key, value.toString());
    };
    // #endregion
}

export default SettingInputTableRow;
