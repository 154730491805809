import { TableCell, TableRow } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';
import { API, ingredientTypeText } from '../../api';
import { IngredientItem } from '../../api/response';

// #region UI
const styles = () =>
    createStyles({
        photo: {
            height: 36,
            objectFit: 'cover',
            width: 36,
        },
    });
// #endregion

// #region Props & State
interface ComponentProps extends WithStyles<typeof styles> {
    item: IngredientItem;
    onItemClick: (item: IngredientItem) => void;
}
// #endregion

class IngredientTableRow extends React.Component<ComponentProps> {
    // #region Lifecycle
    public shouldComponentUpdate(nextProps: Readonly<ComponentProps>): boolean {
        const currentItem = this.props.item;
        const nextItem = nextProps.item;

        return (
            currentItem.id !== nextItem.id ||
            currentItem.name !== nextItem.name ||
            currentItem.type !== nextItem.type ||
            currentItem.photoFilename !== nextItem.photoFilename
        );
    }

    public render(): React.ReactNode {
        const { classes, item } = this.props;

        return (
            <TableRow hover={true} onClick={this.onItemClick}>
                <TableCell>{item.id}</TableCell>
                <TableCell>
                    <img
                        alt={item.name}
                        className={classes.photo}
                        src={API.ingredients.photoUrl(item.id, item.photoFilename)}
                    />
                </TableCell>
                <TableCell>{ingredientTypeText(item.type)}</TableCell>
                <TableCell>{item.name}</TableCell>
            </TableRow>
        );
    }
    // #endregion

    // #region Private
    private onItemClick = (): void => {
        const { item, onItemClick } = this.props;
        onItemClick(item);
    };
    // #endregion
}

export default withStyles(styles)(IngredientTableRow);
