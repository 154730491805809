import React from 'react';

export interface JoditEditorProps {
    initialValue: string;
    config: object;
    onChange: (value: string) => void;
}

// to test new jodit version:
// npm install jodit
// import Jodit from 'jodit';
// import 'jodit/build/jodit.min.css';
// replace window.Jodit with Jodit
// remove <script /> and <link>

export default class JoditEditor extends React.Component<JoditEditorProps> {
    private editor: Jodit | null;

    private textareaRef: React.RefObject<HTMLTextAreaElement>;

    constructor(props: JoditEditorProps) {
        super(props);

        this.editor = null;
        this.textareaRef = React.createRef();
    }

    // #region Lifecycle
    public componentDidMount(): void {
        this.createEditor();
    }

    public componentWillUnmount(): void {
        if (this.editor !== null) {
            this.editor.destruct();
        }
    }

    public shouldComponentUpdate(nextProps: Readonly<JoditEditorProps>): boolean {
        return nextProps.config !== this.props.config;
    }

    public componentDidUpdate(prevProps: Readonly<JoditEditorProps>): void {
        if (prevProps.config !== this.props.config) {
            this.createEditor();
        }
    }

    public render(): React.ReactNode {
        return (
            <React.Fragment>
                <textarea ref={this.textareaRef} />
            </React.Fragment>
        );
    }
    // #endregion

    // #region Private
    private createEditor = (): void => {
        if (this.textareaRef.current === null) {
            return;
        }

        if (this.editor !== null) {
            this.editor.destruct();
        }

        this.editor = new window.Jodit(this.textareaRef.current, this.props.config);
        this.editor.value = this.props.initialValue;
        this.editor.events.on('change', this.changeListener);
    };

    private changeListener = (value: string): void => {
        this.props.onChange(value);
    };
    // #endregion
}
