import { combineReducers } from 'redux';
import { StoreState } from '../StoreState';
import { auth } from './auth';
import { currentMenuItem } from './currentMenuItem';
import { error } from './error';
import { message } from './message';
import { ongoingActions } from './ongoingActions';
import { title } from './title';

export default combineReducers<StoreState>({
    auth,
    currentMenuItem,
    error,
    message,
    ongoingActions,
    title,
});
