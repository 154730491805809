import { Grid, Typography, Button } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import React, { CSSProperties } from 'react';
import Dropzone from 'react-dropzone';
import { connect, MapStateToPropsParam } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ActionCreator, ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import { API } from '../../api';
import withAppCanvas, { AppCanvasComponentProps } from '../../components/AppCanvas';
import { MenuItemType } from '../../model/menuItems';
import { StoreState } from '../../store';
import {
    setFailState,
    SetFailStateAction,
    setInProgressState,
    SetInProgressStateAction,
    setSuccessState,
    SetSuccessStateAction,
    showError,
    ShowErrorAction,
    showMessage,
    ShowMessageAction,
} from '../../store/actions';

// #region UI
const strings = {
    button: {
        clear: 'Wyczyść dane',
    },
    error: {
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
    message: {
        importExcelSucceed: 'Import linków partnerskich zakończony sukcesem.',
        importPhotoSucceed: 'Import zdjęcia przycisku zakończony sukcesem.',
        clearSucceed: 'Dane o linkach partnerskich zostały wyczyszczone.',
    },
    text: {
        importExcel:
            'Przeciągnij tutaj plik Excel (xlsx) lub kliknij, aby wybrać plik. Aktualizacja rozpocznie się automatycznie.',
        importPhotos:
            'Przeciągnij tutaj zdjęcie przycisku (pliki png) lub kliknij, aby wybrać pliki. Aktualizacja rozpocznie się automatycznie.',
    },
};
// #endregion

// #region Props & State
interface StateProps {}
interface DispatchProps {
    readonly dispatchSetInProgressState: () => void;
    readonly dispatchSetSuccessState: () => void;
    readonly dispatchSetFailState: () => void;
    readonly dispatchShowError: (error: string) => void;
    readonly dispatchShowMessage: (message: string) => void;
}
interface OwnProps extends RouteComponentProps, AppCanvasComponentProps {}
type ComponentProps = StateProps & DispatchProps & OwnProps;
// #endregion

class ImportAffiliateLinksPage extends React.Component<ComponentProps> {
    constructor(props: ComponentProps) {
        super(props);

        const { dispatchChangeMenuItem, dispatchSetTitle } = this.props;
        dispatchChangeMenuItem(MenuItemType.AffiliateLinks);
        dispatchSetTitle(MenuItemType.AffiliateLinks);
    }

    // #region Lifecycle
    public render(): React.ReactNode {
        const style: CSSProperties = {
            borderColor: '#666666',
            borderRadius: 5,
            borderStyle: 'dashed',
            borderWidth: 2,
            height: 150,
            marginTop: 16,
            position: 'relative',
            width: 'auto',
        };

        return (
            <Grid container={true} direction="row">
                <Grid item={true} xs={12}>
                    <Dropzone
                        multiple={false}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onDropAccepted={this.onExcelFileAccepted}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Grid
                                    container={true}
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    spacing={1}
                                    style={style}
                                >
                                    <Grid item={true}>
                                        <InsertDriveFileIcon fontSize="large" />
                                    </Grid>
                                    <Grid item={true}>
                                        <Typography>{strings.text.importExcel}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </Dropzone>
                </Grid>
                <Grid item={true} xs={12}>
                    <Dropzone multiple={false} accept="image/png" onDropAccepted={this.onPhotoFileAccepted}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Grid
                                    container={true}
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    direction="column"
                                    spacing={1}
                                    style={style}
                                >
                                    <Grid item={true}>
                                        <InsertPhotoIcon fontSize="large" />
                                    </Grid>
                                    <Grid item={true}>
                                        <Typography>{strings.text.importPhotos}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </Dropzone>
                </Grid>
                <Grid item={true} xs={12} style={{ paddingTop: 10, textAlign: 'right' }}>
                    <Button variant="contained" color="primary" onClick={this.onClearClick}>
                        {strings.button.clear}
                    </Button>
                </Grid>
            </Grid>
        );
    }
    // #endregion

    // #region Private
    private onExcelFileAccepted = async (files: File[]): Promise<void> => {
        const {
            dispatchSetInProgressState,
            dispatchSetSuccessState,
            dispatchSetFailState,
            dispatchShowError,
            dispatchShowMessage,
        } = this.props;
        if (files.length === 1) {
            try {
                dispatchSetInProgressState();
                await API.affiliate.import(files[0]);
                dispatchSetSuccessState();
                dispatchShowMessage(strings.message.importExcelSucceed);
            } catch (error) {
                dispatchSetFailState();

                if (error !== null) {
                    const message = error.message ? error.message : strings.error.unknown;
                    dispatchShowError(message);
                }
            }
        }
    };

    private onPhotoFileAccepted = async (files: File[]): Promise<void> => {
        const {
            dispatchSetInProgressState,
            dispatchSetSuccessState,
            dispatchSetFailState,
            dispatchShowError,
            dispatchShowMessage,
        } = this.props;
        if (files.length === 1) {
            try {
                dispatchSetInProgressState();
                await API.affiliate.setBuyButtonPhoto(files[0]);
                dispatchSetSuccessState();
                dispatchShowMessage(strings.message.importPhotoSucceed);
            } catch (error) {
                dispatchSetFailState();

                if (error !== null) {
                    const message = error.message ? error.message : strings.error.unknown;
                    dispatchShowError(message);
                }
            }
        }
    };

    private onClearClick = async (): Promise<void> => {
        const {
            dispatchSetInProgressState,
            dispatchSetSuccessState,
            dispatchSetFailState,
            dispatchShowError,
            dispatchShowMessage,
        } = this.props;
        try {
            dispatchSetInProgressState();
            await API.affiliate.clear();
            dispatchSetSuccessState();
            dispatchShowMessage(strings.message.clearSucceed);
        } catch (error) {
            dispatchSetFailState();

            if (error !== null) {
                const message = error.message ? error.message : strings.error.unknown;
                dispatchShowError(message);
            }
        }
    };
    // #endregion
}

// #region Connect
interface ActionDispatches {
    dispatchSetInProgressState: ActionCreator<SetInProgressStateAction>;
    dispatchSetSuccessState: ActionCreator<SetSuccessStateAction>;
    dispatchSetFailState: ActionCreator<SetFailStateAction>;
    dispatchShowError: ActionCreator<ShowErrorAction>;
    dispatchShowMessage: ActionCreator<ShowMessageAction>;
}

interface ActionCreators
    extends ActionCreatorsMapObject<
        SetInProgressStateAction | SetSuccessStateAction | SetFailStateAction | ShowErrorAction | ShowMessageAction
    > {
    dispatchSetInProgressState: ActionCreator<SetInProgressStateAction>;
    dispatchSetSuccessState: ActionCreator<SetSuccessStateAction>;
    dispatchSetFailState: ActionCreator<SetFailStateAction>;
    dispatchShowError: ActionCreator<ShowErrorAction>;
    dispatchShowMessage: ActionCreator<ShowMessageAction>;
}

const mapDispatchToProps = (dispatch: Dispatch): ActionDispatches =>
    bindActionCreators<ActionCreators>(
        {
            dispatchSetFailState: setFailState,
            dispatchSetInProgressState: setInProgressState,
            dispatchSetSuccessState: setSuccessState,
            dispatchShowError: showError,
            dispatchShowMessage: showMessage,
        },
        dispatch
    );

const mapStateToProps: MapStateToPropsParam<StateProps, {}, StoreState> = (state: StoreState): StateProps => ({
    error: state.error,
    message: state.message,
});

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
    mapStateToProps,
    mapDispatchToProps
)(withAppCanvas(ImportAffiliateLinksPage));
// #endregion
