import { CircularProgress, Portal, Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { StoreState } from '../../store';

// #region UI
const styles = (theme: Theme) =>
    createStyles({
        background: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            height: '100%',
            left: 0,
            position: 'fixed',
            top: 0,
            width: '100%',
            willChange: 'opacity',
            zIndex: -1,
        },
        container: {
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            left: 0,
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: 1300,
        },
        progress: {
            alignItems: 'center',
            background: theme.palette.common.white,
            borderRadius: 5,
            display: 'flex',
            flex: '0 1 auto',
            flexDirection: 'column',
            height: 100,
            justifyContent: 'center',
            position: 'relative',
            width: 100,
        },
    });
// #endregion

// #region Props & State
interface StateProps {
    readonly showLoading: boolean;
}
interface DispatchProps {}
interface OwnProps {}
type ComponentProps = WithStyles<typeof styles> & StateProps & DispatchProps & OwnProps;
// #endregion

class Loading extends React.Component<ComponentProps> {
    public render(): React.ReactNode {
        const { classes, showLoading } = this.props;

        if (showLoading) {
            return (
                <Portal>
                    <div className={classes.container}>
                        <div className={classes.background} />
                        <div className={classes.progress}>
                            <CircularProgress />
                        </div>
                    </div>
                </Portal>
            );
        } else {
            return null;
        }
    }
}

// #region Connect
const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, StoreState> = (state: StoreState): StateProps => ({
    showLoading: state.ongoingActions > 0,
});

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(mapStateToProps)(withStyles(styles)(Loading));
// #endregion
