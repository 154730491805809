import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';
import { MenuItemType } from '../model/menuItems';

// #region Props & State
interface OwnProps {
    onChange: (menuItem: MenuItemType, path: string) => void;
    isSelected: boolean;
    icon: React.ComponentType<SvgIconProps>;
    menuItem: MenuItemType;
    path: string;
}
type ComponentProps = OwnProps;
// #endregion

class DrawerListItem extends React.Component<ComponentProps> {
    // #region Lifecycle
    public render(): React.ReactNode {
        const { isSelected, menuItem, icon } = this.props;
        const SvgIcon = icon;

        return (
            <ListItem onClick={this.onListItemClick} button={true} selected={isSelected}>
                <ListItemIcon>
                    <SvgIcon />
                </ListItemIcon>
                <ListItemText primary={menuItem} />
            </ListItem>
        );
    }
    // #endregion

    // #region Private
    private onListItemClick = (): void => {
        const { onChange, menuItem, path } = this.props;
        onChange(menuItem, path);
    };
    // #endregion
}

export default DrawerListItem;
