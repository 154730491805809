import { Grid, Typography } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import React, { CSSProperties } from 'react';
import Dropzone from 'react-dropzone';
import { connect, MapStateToPropsParam } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ActionCreator, ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import { API } from '../../api';
import withAppCanvas, { AppCanvasComponentProps } from '../../components/AppCanvas';
import { MenuItemType } from '../../model/menuItems';
import { StoreState } from '../../store';
import {
    setFailState,
    SetFailStateAction,
    setInProgressState,
    SetInProgressStateAction,
    setSuccessState,
    SetSuccessStateAction,
    showError,
    ShowErrorAction,
    showMessage,
    ShowMessageAction,
} from '../../store/actions';

// #region UI
const strings = {
    error: {
        maxPhotosToUpload: 'Możesz przesłać maksymalnie 20 plików za jednym razem.',
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
    message: {
        importExcelSucceed: 'Import produktów zakończony sukcesem.',

        importPhotosSucceed: 'Import zdjęć produktów zakończony sukcesem.',
    },
    text: {
        importExcel:
            'Przeciągnij tutaj plik Excel (xlsx) lub kliknij, aby wybrać plik. Aktualizacja rozpocznie się automatycznie.',
        importPhotos:
            'Przeciągnij tutaj zdjęcia (pliki png) lub kliknij, aby wybrać pliki. Aktualizacja rozpocznie się automatycznie.',
    },
};
// #endregion

// #region Props & State
interface StateProps {}
interface DispatchProps {
    readonly dispatchSetInProgressState: () => void;
    readonly dispatchSetSuccessState: () => void;
    readonly dispatchSetFailState: () => void;
    readonly dispatchShowError: (error: string) => void;
    readonly dispatchShowMessage: (message: string) => void;
}
interface OwnProps extends RouteComponentProps, AppCanvasComponentProps {}
type ComponentProps = StateProps & DispatchProps & OwnProps;
// #endregion

class ImportProductsPage extends React.Component<ComponentProps> {
    constructor(props: ComponentProps) {
        super(props);

        const { dispatchChangeMenuItem, dispatchSetTitle } = this.props;
        dispatchChangeMenuItem(MenuItemType.ProductsImport);
        dispatchSetTitle(MenuItemType.ProductsImport);
    }

    // #region Lifecycle
    public render(): React.ReactNode {
        const style: CSSProperties = {
            borderColor: '#666666',
            borderRadius: 5,
            borderStyle: 'dashed',
            borderWidth: 2,
            height: 150,
            marginTop: 16,
            position: 'relative',
            width: 'auto',
        };

        return (
            <Grid container={true} direction="row">
                <Grid item={true} xs={12}>
                    <Dropzone
                        multiple={false}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onDropAccepted={this.onExcelFileAccepted}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Grid
                                    container={true}
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    spacing={1}
                                    style={style}
                                >
                                    <Grid item={true}>
                                        <InsertDriveFileIcon fontSize="large" />
                                    </Grid>
                                    <Grid item={true}>
                                        <Typography>{strings.text.importExcel}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </Dropzone>
                </Grid>
                <Grid item={true} xs={12}>
                    <Dropzone multiple={true} accept="image/png" onDropAccepted={this.onPhotoFilesAccepted}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Grid
                                    container={true}
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    direction="column"
                                    spacing={1}
                                    style={style}
                                >
                                    <Grid item={true}>
                                        <InsertPhotoIcon fontSize="large" />
                                    </Grid>
                                    <Grid item={true}>
                                        <Typography>{strings.text.importPhotos}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </Dropzone>
                </Grid>
            </Grid>
        );
    }
    // #endregion

    // #region Private
    private onExcelFileAccepted = async (files: File[]): Promise<void> => {
        const {
            dispatchSetInProgressState,
            dispatchSetSuccessState,
            dispatchSetFailState,
            dispatchShowError,
            dispatchShowMessage,
        } = this.props;
        if (files.length === 1) {
            try {
                dispatchSetInProgressState();
                await API.products.excel(files[0]);
                dispatchSetSuccessState();
                dispatchShowMessage(strings.message.importExcelSucceed);
            } catch (error) {
                dispatchSetFailState();

                if (error !== null) {
                    const message = error.message ? error.message : strings.error.unknown;
                    dispatchShowError(message);
                }
            }
        }
    };

    private onPhotoFilesAccepted = async (files: File[]): Promise<void> => {
        const {
            dispatchSetInProgressState,
            dispatchSetSuccessState,
            dispatchSetFailState,
            dispatchShowError,
            dispatchShowMessage,
        } = this.props;
        if (files.length > 0 && files.length <= 20) {
            try {
                dispatchSetInProgressState();
                await API.products.photos(files);
                dispatchSetSuccessState();
                dispatchShowMessage(strings.message.importPhotosSucceed);
            } catch (error) {
                dispatchSetFailState();

                if (error !== null) {
                    const message = error.message ? error.message : strings.error.unknown;
                    dispatchShowError(message);
                }
            }
        } else if (files.length > 20) {
            const message = strings.error.maxPhotosToUpload;
            dispatchShowError(message);
        }
    };
    // #endregion
}

// #region Connect
interface ActionDispatches {
    dispatchSetInProgressState: ActionCreator<SetInProgressStateAction>;
    dispatchSetSuccessState: ActionCreator<SetSuccessStateAction>;
    dispatchSetFailState: ActionCreator<SetFailStateAction>;
    dispatchShowError: ActionCreator<ShowErrorAction>;
    dispatchShowMessage: ActionCreator<ShowMessageAction>;
}

interface ActionCreators
    extends ActionCreatorsMapObject<
        SetInProgressStateAction | SetSuccessStateAction | SetFailStateAction | ShowErrorAction | ShowMessageAction
    > {
    dispatchSetInProgressState: ActionCreator<SetInProgressStateAction>;
    dispatchSetSuccessState: ActionCreator<SetSuccessStateAction>;
    dispatchSetFailState: ActionCreator<SetFailStateAction>;
    dispatchShowError: ActionCreator<ShowErrorAction>;
    dispatchShowMessage: ActionCreator<ShowMessageAction>;
}

const mapDispatchToProps = (dispatch: Dispatch): ActionDispatches =>
    bindActionCreators<ActionCreators>(
        {
            dispatchSetFailState: setFailState,
            dispatchSetInProgressState: setInProgressState,
            dispatchSetSuccessState: setSuccessState,
            dispatchShowError: showError,
            dispatchShowMessage: showMessage,
        },
        dispatch
    );

const mapStateToProps: MapStateToPropsParam<StateProps, {}, StoreState> = (state: StoreState): StateProps => ({
    error: state.error,
    message: state.message,
});

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
    mapStateToProps,
    mapDispatchToProps
)(withAppCanvas(ImportProductsPage));
// #endregion
