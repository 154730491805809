import { Action, ActionCreator } from 'redux';
import { ErrorActionType } from './actionTypes';

// #region ShowError
export interface ShowErrorAction extends Action<ErrorActionType.ShowError> {
    error: Error;
}

export const showError: ActionCreator<ShowErrorAction> = (message: string, name: string): ShowErrorAction => ({
    error: { message, name },
    type: ErrorActionType.ShowError,
});
// #endregion

// #region ClearError
export interface ClearErrorAction extends Action<ErrorActionType.ClearError> {}

export const clearError: ActionCreator<ClearErrorAction> = (): ClearErrorAction => ({
    type: ErrorActionType.ClearError,
});
// #endregion

export type ErrorAction = ShowErrorAction | ClearErrorAction;
