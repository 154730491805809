export enum MenuItemType {
    Categories = 'Kategorie',
    Ingredients = 'Składniki',
    Diets = 'Diety',
    Articles = 'Artykuły',
    Notifications = 'Powiadomienia push',
    Versions = 'Wersje',
    Settings = 'Ustawienia',
    Import = 'Import',
    Products = 'Produkty',
    ProductsImport = 'Import Produktów',
    AffiliateLinks = 'Linki partnerskie',
}
