import { Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { StoreState } from '..';
import { API } from '../../api';
import { AuthResponse } from '../../api/response';
import localStore from '../LocalStore';
import { AuthActionType } from './actionTypes';
import { setFailState, setInProgressState, setSuccessState, StateAction } from './stateActions';

// #region AuthenticateUser
interface AuthenticateUserAction extends Action<AuthActionType.AuthenticateUser> {
    token: AuthResponse;
}

const authenticateUser: ActionCreator<AuthenticateUserAction> = (token: AuthResponse): AuthenticateUserAction => ({
    token,
    type: AuthActionType.AuthenticateUser,
});

export type AuthenticateUserAsync = (username: string, password: string) => Promise<AuthResponse>;
export type AuthenticateUserAsyncAction = ThunkAction<
    Promise<AuthResponse>,
    StoreState,
    {},
    AuthenticateUserAction | StateAction
>;

export const authenticateUserAsync: ActionCreator<AuthenticateUserAsyncAction> = (
    username: string,
    password: string
): AuthenticateUserAsyncAction => {
    return async (dispatch): Promise<AuthResponse> => {
        return API.auth.token(username, password).then((authResponse: AuthResponse) => {
            localStore.storeAuth({
                date: new Date(),
                token: authResponse.token,
            });
            dispatch(authenticateUser(authResponse));
            return authResponse;
        });
    };
};
// #endregion

// #region LogOutUser
interface LogOutUserAction extends Action<AuthActionType.LogOutUser> {}

const logOutUser: ActionCreator<LogOutUserAction> = (): LogOutUserAction => ({
    type: AuthActionType.LogOutUser,
});

export type LogOutUserAsync = () => Promise<void>;
export type LogOutUserAsyncAction = ThunkAction<Promise<void>, StoreState, {}, LogOutUserAction | StateAction>;

export const logoutUserAsync: ActionCreator<LogOutUserAsyncAction> = (): LogOutUserAsyncAction => {
    return async (dispatch): Promise<void> => {
        dispatch(setInProgressState());
        return new Promise<void>((resolve) => {
            localStore.clearStore();
            resolve();
        })
            .then(() => {
                dispatch(setSuccessState());
                dispatch(logOutUser());
            })
            .catch(() => {
                dispatch(setFailState());
                dispatch(logOutUser());
            });
    };
};
// #endregion

export type AuthAction = AuthenticateUserAction | LogOutUserAction;
