import { Action, ActionCreator } from 'redux';
import { ActionStateType } from './actionTypes';

// #region SetInProgressState
export interface SetInProgressStateAction extends Action<ActionStateType.SetInProgressState> {}

export const setInProgressState: ActionCreator<SetInProgressStateAction> = (): SetInProgressStateAction => ({
    type: ActionStateType.SetInProgressState,
});
// #endregion

// #region SetSuccessState
export interface SetSuccessStateAction extends Action<ActionStateType.SetSuccessState> {}

export const setSuccessState: ActionCreator<SetSuccessStateAction> = (): SetSuccessStateAction => ({
    type: ActionStateType.SetSuccessState,
});
// #endregion

// #region SetFailState
export interface SetFailStateAction extends Action<ActionStateType.SetFailState> {}

export const setFailState: ActionCreator<SetFailStateAction> = (): SetFailStateAction => ({
    type: ActionStateType.SetFailState,
});
// #endregion

export type StateAction = SetInProgressStateAction | SetSuccessStateAction | SetFailStateAction;
