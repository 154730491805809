export enum ActionStateType {
    SetInProgressState = 'SetInProgressState',
    SetSuccessState = 'SetSuccessState',
    SetFailState = 'SetFailState',
}

export enum AuthActionType {
    AuthenticateUser = 'AuthenticateUser',
    LogOutUser = 'LogOutUser',
}

export enum ErrorActionType {
    ShowError = 'ShowError',
    ClearError = 'ClearError',
}

export enum MenuItemActionType {
    ChangeMenuItem = 'ChangeMenuItem',
    ClearMenuItem = 'ClearMenuItem',
}

export enum MessageActionType {
    ShowMessage = 'ShowMessage',
    HideMessage = 'HideMessage',
}

export enum TitleActionType {
    SetTitle = 'SetTitle',
    ClearTitle = 'ClearTitle',
}
