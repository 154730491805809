import { Reducer } from 'redux';
import { MenuItemType } from '../../model/menuItems';
import { MenuItemAction, MenuItemActionType } from '../actions';

type ReducerState = MenuItemType | null;
type ReducerAction = MenuItemAction;

const initialState: ReducerState = null;

export const currentMenuItem: Reducer<ReducerState, ReducerAction> = (
    state = initialState,
    action: ReducerAction
): ReducerState => {
    switch (action.type) {
        case MenuItemActionType.ChangeMenuItem:
            return action.current;
        case MenuItemActionType.ClearMenuItem:
            return null;
        default:
            return state;
    }
};
