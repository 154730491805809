import { AppBar, Button, Theme, Toolbar, Typography } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ActionCreator, ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import { ThunkActionDispatch } from 'redux-thunk';
import { StoreState } from '../store';
import { LogOutUserAsync, logoutUserAsync, LogOutUserAsyncAction } from '../store/actions';
import { drawerWidth } from './DefaultDrawer';

// #region UI
const strings = {
    button: {
        logout: 'Wyloguj',
    },
    text: {
        appTitle: 'Wiesz Co Jesz',
    },
};

const styles = (theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        title: {
            flexGrow: 1,
            paddingLeft: drawerWidth,
        },
    });
// #endregion

// #region Props & State
interface StateProps {
    readonly title: string | null;
}
interface DispatchProps {
    readonly dispatchLogoutUserAsync: LogOutUserAsync;
}
interface OwnProps {}
type ComponentProps = WithStyles<typeof styles> & StateProps & DispatchProps & OwnProps;

interface ComponentState {}
// #endregion

class DefaultAppBar extends React.Component<ComponentProps, ComponentState> {
    // #region Lifecycle
    public render(): React.ReactNode {
        const { classes, title } = this.props;

        return (
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap={true} className={classes.title}>
                        {title !== null ? title : strings.text.appTitle}
                    </Typography>
                    <Button color="inherit" onClick={this.onLogoutClick}>
                        {strings.button.logout}
                    </Button>
                </Toolbar>
            </AppBar>
        );
    }
    // #endregion

    // #region Private
    private onLogoutClick = async (): Promise<void> => {
        const { dispatchLogoutUserAsync } = this.props;
        await dispatchLogoutUserAsync();
    };
    // #endregion
}

// #region Connect
interface ActionDispatches {
    dispatchLogoutUserAsync: ThunkActionDispatch<ActionCreator<LogOutUserAsyncAction>>;
}

interface ActionCreators extends ActionCreatorsMapObject<LogOutUserAsyncAction> {
    dispatchLogoutUserAsync: ActionCreator<LogOutUserAsyncAction>;
}

const mapDispatchToProps = (dispatch: Dispatch): ActionDispatches =>
    bindActionCreators<ActionCreators>(
        {
            dispatchLogoutUserAsync: logoutUserAsync,
        },
        dispatch
    );

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, StoreState> = (state: StoreState): StateProps => ({
    title: state.title,
});

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(DefaultAppBar));
// #endregion
