import MomentUtils from '@date-io/moment';
import {
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import Dropzone from 'react-dropzone';
import { connect, MapStateToPropsParam } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ActionCreator, ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import Validator from 'validator';
import { API } from '../../api';
import { ArticleData } from '../../api/request/ArticleData';
import { ArticleDetails } from '../../api/response';
import withAppCanvas, { AppCanvasComponentProps } from '../../components/AppCanvas';
import JoditEditor from '../../components/JoditEditor';
import { MenuItemType } from '../../model/menuItems';
import { routesDetails } from '../../routes/routesDetails';
import { StoreState } from '../../store';
import {
    setFailState,
    SetFailStateAction,
    setInProgressState,
    SetInProgressStateAction,
    setSuccessState,
    SetSuccessStateAction,
    showError,
    ShowErrorAction,
    showMessage,
    ShowMessageAction,
} from '../../store/actions';

// #region UI
const dateTimeFormat: string = 'YYYY-MM-DD HH:mm';

const strings = {
    button: {
        cancel: 'Anuluj',
        clear: 'Wyczyść',
        delete: 'Usuń',
        ok: 'OK',
        save: 'Zapisz',
    },
    error: {
        contentLength: 'Wymagana wartość w przedziale <1, 512> znaków',
        contentRequired: 'Treść jest wymagana',
        invalidDateTime: 'Podano błędną datę i/lub czas',
        leadLength: 'Wymagana wartość w przedziale <1, 512> znaków',
        leadRequired: 'Wprowadzenie jest wymagane',
        titleLength: 'Wymagana wartość w przedziale <1, 128> znaków',
        titleRequired: 'Tytuł jest wymagany',
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
    label: {
        content: 'Treść',
        lead: 'Wprowadzenie',
        publicationDate: 'Data publikacji',
        sendPushNotification: 'Wyślij powiadomienie push',
        title: 'Tytuł',
    },
    message: {
        added: 'Nowy artykuł został dodany',
        edited: 'Artykuł został zaktualizowany',
        photoAdded: 'Zdjęcie zostało zapisane',
    },
    placeholder: {
        content: 'Wpisz treść artykułu',
    },
    text: {
        addPhoto:
            'Zdjęcie na liście - Przeciągnij tutaj zdjęcie (pliki png/jpg/webp) lub kliknij, aby wybrać plik. Zapis zdjęcia rozpocznie się automatycznie.',
        addDetailPhoto:
            'Zdjęcie główne - Przeciągnij tutaj zdjęcie (pliki png/jpg/webp) lub kliknij, aby wybrać plik. Zapis zdjęcia rozpocznie się automatycznie.',
    },
};
// #endregion

// #region Form
enum FormFieldNames {
    Title = 'title',
    Lead = 'lead',
    Content = 'content',
    PhotoFilename = 'photoFilename',
    DetailPhotoFilename = 'detailPhotoFilename',
    PublicationDate = 'publicationDate',
    SendPushNotification = 'sendPushNotification',
}

interface FormFields {
    title: string;
    lead: string;
    content: string;
    photoFilename: string | null;
    detailPhotoFilename: string | null;
    publicationDate: string | null;
    sendPushNotification: boolean;
}

interface FormErrors {
    title?: string;
    lead?: string;
    content?: string;
}
// #endregion

// #region Props & State
interface StateProps {}
interface DispatchProps {
    readonly dispatchSetInProgressState: () => void;
    readonly dispatchSetSuccessState: () => void;
    readonly dispatchSetFailState: () => void;
    readonly dispatchShowError: (error: string) => void;
    readonly dispatchShowMessage: (message: string) => void;
}
interface RouteParams {
    articleId: string;
}
interface OwnProps extends RouteComponentProps<RouteParams>, AppCanvasComponentProps {}
type ComponentProps = StateProps & DispatchProps & OwnProps;

interface ComponentState {
    loading: boolean;
    loaded: boolean;
    errors: FormErrors;
    fields: FormFields;
}

const initialState: ComponentState = {
    errors: {},
    fields: {
        content: '',
        lead: '',
        photoFilename: null,
        detailPhotoFilename: null,
        publicationDate: null,
        sendPushNotification: false,
        title: '',
    },
    loaded: false,
    loading: false,
};
// #endregion

class ArticleDetailsPage extends React.Component<ComponentProps, ComponentState> {
    private readonly buttons = [
        'bold',
        'strikethrough',
        'underline',
        'italic',
        '|',
        'superscript',
        'subscript',
        '|',
        'ul',
        'ol',
        '|',
        'outdent',
        'indent',
        '|',
        'font',
        'fontsize',
        'brush',
        'paragraph',
        '|',
        'image',
        'link',
        '|',
        'align',
        'undo',
        'redo',
    ];

    private readonly config = {
        activeButtonsInReadOnly: [],
        addNewLine: false,
        addNewLineOnDBLClick: false,
        addNewLineTagsTriggers: ['table', 'iframe', 'img', 'hr', 'jodit'],
        allowResizeX: false,
        allowResizeY: false,
        askBeforePasteFromWord: true,
        askBeforePasteHTML: true,
        autofocus: false,
        beautifyHTML: true,
        // beautifyHTMLCDNUrlsJS: [use defaults]
        buttons: this.buttons,
        buttonsMD: this.buttons,
        buttonsSM: this.buttons,
        buttonsXS: this.buttons,
        // cleanHTML:: {use defaults{}
        colorPickerDefaultTab: 'text',
        // commandToHotkeys: {use defaults}
        // controls: {use defaults}
        debugLanguage: false,
        defaultActionOnPaste: 'insert_as_html',
        // defaultAjaxOptions: {use defaults}
        defaultMode: 3, // Jodit.MODE_SPLIT
        // dialog: {use defaults}
        direction: 'ltr',
        disablePlugins: [],
        disabled: false,
        draggableTags: ['img', 'a', 'jodit-media', 'jodit'],
        editorCssClass: false,
        enableDragAndDropFileToEditor: true,
        enter: 'p', // Jodit.PARAGRAPH
        enterBlock: 'p', // Jodit.PARAGRAPH
        events: {},
        extraButtons: [],
        // filebrowser: {use defaults}
        fullsize: false,
        globalFullsize: false,
        height: 400,
        i18n: { pl: {} },
        iframe: false,
        iframeBaseUrl: '',
        iframeCSSLinks: [],
        iframeDefaultSrc: 'about:blank',
        // iframeStyle: "use detaults"
        image: {
            editAlign: true,
            editAlt: true,
            editBorderRadius: true,
            editClass: true,
            editId: true,
            editLink: true,
            editMargins: true,
            editSize: true,
            editSrc: true,
            editStyle: true,
            editTitle: true,
            openOnDblClick: true,
            selectImageAfterClose: true,
            showPreview: true,
            useImageEditor: true,
        },
        imageDefaultWidth: 300,
        // imageeditor: {use defaults}
        indentMargin: 10,
        inline: false,
        language: 'pl',
        license: '',
        limitChars: false,
        limitHTML: false,
        limitWords: false,
        link: {
            followOnDblClick: false,
            noFollowCheckbox: false,
            openInNewTabCheckbox: false,
            openLinkDialogAfterPost: false,
            processPastedLink: false,
            processVideoLink: false,
            removeLinkAfterFormat: false,
        },
        maxWidth: '100%',
        mediaBlocks: ['video', 'audio'],
        mediaFakeTag: 'jodit-media',
        mediaInFakeBlock: true,
        minHeight: 200,
        minWidth: '200px',
        mobileTapTimeout: 300,
        observer: { timeout: 100 },
        placeholder: strings.placeholder.content,
        // ownerDocument: (use defaults)
        // ownerWindoe: (use defaults)
        // popup: {use links}
        preset: 'custom',
        readonly: false,
        removeButtons: [],
        resizer: { showSize: true, hideSizeTimeout: 1000, min_width: 10, min_height: 10 },
        saveHeightInStorage: false,
        saveModeInStorage: false,
        showCharsCounter: false,
        showMessageErrorOffsetPx: 3,
        showMessageErrorTime: 3000,
        showMessageErrors: true,
        showPlaceholder: true,
        showTooltip: true,
        showTooltipDelay: 500,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        sizeLG: 900,
        sizeMD: 700,
        sizeSM: 400,
        // sourceEditorCDNUrlsJS: [use defaults]
        sourceEditorNativeOptions: {
            highlightActiveLine: true,
            mode: 'ace/mode/html',
            showGutter: true,
            theme: 'ace/theme/idle_fingers',
            wrap: true,
        },
        // specialCharacters: [use defaults]
        spellcheck: false,
        tabIndex: -1,
        textIcons: false,
        theme: 'default', // 'dark
        toolbar: true,
        toolbarAdaptive: true,
        toolbarButtonSize: 'large',
        toolbarDisableStickyForMobile: true,
        toolbarInline: true,
        toolbarInlineDisableFor: [],
        toolbarSticky: true,
        toolbarStickyOffset: 0,
        triggerChangeEvent: true,
        uploader: {
            imagesExtensions: ['jpg', 'png', 'jpeg'],
            insertImageAsBase64URI: true,
            // url: '',
            // headers: null,
            // data: null,
            // format: 'json',
            // prepareData: 'function(e){return e}',
            // isSuccess: 'function(e){return e.success}',
            // getMessage: 'function(e){return void 0!==e.data.messages&&Array.isArray(e.data.messages)?e.data.messages.join(" "):""}',
            // process: 'function(e){return e.data}',
            // error: 'function(e){this.jodit.events.fire("errorMessage",e.message,"error",4e3)}',
            // defaultHandlerSuccess: 'function(a){var s=this;a.files&&a.files.length&&a.files.forEach(function(e,t){var o=a.isImages&&a.isImages[t]?["img","src"]:["a","href"],n=o[0],i=o[1],r=s.jodit.create.inside.element(n);r.setAttribute(i,a.baseurl+e),"a"===n&&(r.innerText=a.baseurl+e),v.isJoditObject(s.jodit)&&("img"===n?s.jodit.selection.insertImage(r,null,s.jodit.options.imageDefaultWidth):s.jodit.selection.insertNode(r))})}',
            // defaultHandlerError: 'function(e){this.jodit.events.fire("errorMessage",e.message)}',
            // contentType: 'function(e){return(void 0===this.jodit.ownerWindow.FormData||"string"==typeof e)&&"application/x-www-form-urlencoded; charset=UTF-8"}',
        },
        useAceEditor: true,
        useExtraClassesOptions: true,
        useIframeResizer: true,
        useImageResizer: true,
        useInputsPlaceholder: true,
        useNativeTooltip: false,
        usePopupForSpecialCharacters: false,
        useSearch: false,
        useSplitMode: false,
        useTableProcessor: true,
        useTableResizer: true,
        width: 'auto',
        zIndex: 0,
    };

    constructor(props: ComponentProps) {
        super(props);

        this.state = initialState;

        const { dispatchChangeMenuItem, dispatchSetTitle } = this.props;
        dispatchChangeMenuItem(MenuItemType.Articles);
        dispatchSetTitle(MenuItemType.Articles);
    }

    // #region Lifecycle
    public componentDidMount(): void {
        this.load();
    }

    public render(): React.ReactNode {
        const { loading, loaded, errors, fields } = this.state;

        if (loading) {
            return (
                <Grid container={true} justify="center">
                    <CircularProgress />
                </Grid>
            );
        }

        if (!loaded) {
            return (
                <Grid container={true} justify="center">
                    <Typography color="error">{strings.error.unknown}</Typography>
                </Grid>
            );
        }

        return (
            <React.Fragment>
                <Grid container={true} spacing={1} direction="row">
                    <Grid item={true} xs={12}>
                        <TextField
                            error={errors.title !== undefined}
                            helperText={errors.title}
                            id={FormFieldNames.Title}
                            name={FormFieldNames.Title}
                            value={fields.title}
                            multiline={false}
                            required={true}
                            inputProps={{ maxLength: 128 }}
                            fullWidth={true}
                            label={strings.label.title}
                            margin="none"
                            onChange={this.onTextFieldChange}
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            error={errors.lead !== undefined}
                            helperText={errors.lead}
                            id={FormFieldNames.Lead}
                            name={FormFieldNames.Lead}
                            value={fields.lead}
                            inputProps={{ maxLength: 512 }}
                            margin="normal"
                            multiline={true}
                            rows={5}
                            required={true}
                            fullWidth={true}
                            label={strings.label.lead}
                            onChange={this.onTextFieldChange}
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <JoditEditor
                            initialValue={fields.content}
                            config={this.config}
                            onChange={this.onContentChange}
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <Dropzone
                            multiple={false}
                            accept="image/png,image/jpeg,image/webp"
                            onDropAccepted={this.onPhotoFileAccepted}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Grid
                                        container={true}
                                        justify="center"
                                        alignItems="center"
                                        alignContent="center"
                                        direction="row"
                                        spacing={1}
                                        style={{
                                            borderColor: '#666666',
                                            borderRadius: 5,
                                            borderStyle: 'dashed',
                                            borderWidth: 2,
                                            marginTop: 16,
                                            minHeight: 150,
                                            position: 'relative',
                                            width: 'auto',
                                        }}
                                    >
                                        {fields.photoFilename === null && (
                                            <React.Fragment>
                                                <Grid item={true} xs={12} style={{ textAlign: 'center' }}>
                                                    <InsertPhotoIcon fontSize="large" />
                                                </Grid>
                                                <Grid item={true} xs={12}>
                                                    <Typography style={{ textAlign: 'center' }}>
                                                        {strings.text.addPhoto}
                                                    </Typography>
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                        {fields.photoFilename !== null && (
                                            <Grid item={true} xs={12} style={{ textAlign: 'center', height: 300 }}>
                                                <img
                                                    alt={fields.title}
                                                    style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                                                    src={API.articles.photo(fields.photoFilename)}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>
                            )}
                        </Dropzone>

                        {fields.photoFilename !== null && (
                            <Tooltip
                                title={strings.button.delete}
                                style={{
                                    backgroundColor: '#ffffff',
                                    bottom: 62,
                                    left: 14,
                                    position: 'relative',
                                }}
                            >
                                <IconButton onClick={this.onDeletePhotoClick}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>

                    <Grid item={true} xs={12}>
                        <Dropzone
                            multiple={false}
                            accept="image/png,image/jpeg,image/webp"
                            onDropAccepted={this.onDetailPhotoFileAccepted}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Grid
                                        container={true}
                                        justify="center"
                                        alignItems="center"
                                        alignContent="center"
                                        direction="row"
                                        spacing={1}
                                        style={{
                                            borderColor: '#666666',
                                            borderRadius: 5,
                                            borderStyle: 'dashed',
                                            borderWidth: 2,
                                            marginTop: 16,
                                            minHeight: 150,
                                            position: 'relative',
                                            width: 'auto',
                                        }}
                                    >
                                        {fields.detailPhotoFilename === null && (
                                            <React.Fragment>
                                                <Grid item={true} xs={12} style={{ textAlign: 'center' }}>
                                                    <InsertPhotoIcon fontSize="large" />
                                                </Grid>
                                                <Grid item={true} xs={12}>
                                                    <Typography style={{ textAlign: 'center' }}>
                                                        {strings.text.addDetailPhoto}
                                                    </Typography>
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                        {fields.detailPhotoFilename !== null && (
                                            <Grid item={true} xs={12} style={{ textAlign: 'center', height: 300 }}>
                                                <img
                                                    alt={fields.title}
                                                    style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                                                    src={API.articles.photo(fields.detailPhotoFilename)}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>
                            )}
                        </Dropzone>

                        {fields.detailPhotoFilename !== null && (
                            <Tooltip
                                title={strings.button.delete}
                                style={{
                                    backgroundColor: '#ffffff',
                                    bottom: 62,
                                    left: 14,
                                    position: 'relative',
                                }}
                            >
                                <IconButton onClick={this.onDeleteDetailPhotoClick}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>

                    <Grid item={true} xs={12}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                                onChange={this.onDateTimeChange}
                                value={
                                    fields.publicationDate !== null
                                        ? moment(fields.publicationDate).format(dateTimeFormat)
                                        : null
                                }
                                allowKeyboardControl={true}
                                ampm={false}
                                autoOk={true}
                                disableFuture={false}
                                disablePast={false}
                                format={dateTimeFormat}
                                inputVariant="standard"
                                invalidDateMessage={strings.error.invalidDateTime}
                                variant="dialog"
                                margin="normal"
                                label={strings.label.publicationDate}
                                cancelLabel={strings.button.cancel}
                                clearable={true}
                                clearLabel={strings.button.clear}
                                okLabel={strings.button.ok}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item={true} xs={12}>
                        <FormControl fullWidth={false} margin="normal" required={true}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={fields.sendPushNotification}
                                            name={FormFieldNames.SendPushNotification}
                                            id={FormFieldNames.SendPushNotification}
                                            onChange={this.onSwitchChange}
                                        />
                                    }
                                    label={strings.label.sendPushNotification}
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>

                    <Grid item={true}>
                        <Button color="primary" variant="contained" onClick={this.onSaveClick}>
                            {strings.button.save}
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
    // #endregion

    // #region Private
    private load = async (): Promise<void> => {
        const { articleId } = this.props.match.params;
        const id: number = parseInt(articleId, 10);

        if (id === 0) {
            this.setState({ loading: false, loaded: true });
        } else {
            try {
                this.setState({ loading: true });
                const response = await API.articles.details(id);
                this.setState({
                    fields: {
                        content: response.content,
                        lead: response.lead,
                        photoFilename: response.photoFilename,
                        detailPhotoFilename: response.detailPhotoFilename,
                        publicationDate:
                            response.publicationDate !== null
                                ? moment(response.publicationDate).format(dateTimeFormat)
                                : null,
                        sendPushNotification: response.sendPushNotification,
                        title: response.title,
                    },
                    loaded: true,
                    loading: false,
                });
            } catch (error) {
                this.setState({ loading: false });
            }
        }
    };

    private onTextFieldChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>
    ): void => {
        this.setState({
            fields: { ...this.state.fields, [event.target.name]: event.target.value },
        });
    };

    private onContentChange = (value: string): void => {
        this.setState({
            fields: { ...this.state.fields, content: value },
        });
    };

    private onDateTimeChange = (date: MaterialUiPickersDate): void => {
        this.setState({
            fields: {
                ...this.state.fields,
                publicationDate: date !== null && date.isValid() ? date.format(dateTimeFormat) : null,
            },
        });
    };

    private onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        this.setState({
            fields: { ...this.state.fields, [event.target.name]: checked },
        });
    };

    private onPhotoFileAccepted = async (files: File[]): Promise<void> => {
        const {
            dispatchSetInProgressState,
            dispatchSetSuccessState,
            dispatchSetFailState,
            dispatchShowError,
            dispatchShowMessage,
        } = this.props;
        if (files.length > 0) {
            try {
                dispatchSetInProgressState();
                const response = await API.articles.addPhoto(files[0]);
                this.setState({
                    fields: { ...this.state.fields, photoFilename: response.filename },
                });
                dispatchSetSuccessState();
                dispatchShowMessage(strings.message.photoAdded);
            } catch (error) {
                dispatchSetFailState();

                if (error !== null) {
                    const message = error.message ? error.message : strings.error.unknown;
                    dispatchShowError(message);
                }
            }
        }
    };

    private onDetailPhotoFileAccepted = async (files: File[]): Promise<void> => {
        const {
            dispatchSetInProgressState,
            dispatchSetSuccessState,
            dispatchSetFailState,
            dispatchShowError,
            dispatchShowMessage,
        } = this.props;
        if (files.length > 0) {
            try {
                dispatchSetInProgressState();
                const response = await API.articles.addPhoto(files[0]);
                this.setState({
                    fields: { ...this.state.fields, detailPhotoFilename: response.filename },
                });
                dispatchSetSuccessState();
                dispatchShowMessage(strings.message.photoAdded);
            } catch (error) {
                dispatchSetFailState();

                if (error !== null) {
                    const message = error.message ? error.message : strings.error.unknown;
                    dispatchShowError(message);
                }
            }
        }
    };

    private onDeletePhotoClick = (): void => {
        this.setState({
            fields: { ...this.state.fields, photoFilename: null },
        });
    };

    private onDeleteDetailPhotoClick = (): void => {
        this.setState({
            fields: { ...this.state.fields, detailPhotoFilename: null },
        });
    };

    private onSaveClick = async (): Promise<void> => {
        const errors: FormErrors = this.validateFormFields(this.state.fields);
        this.setState({ errors });

        if (_.isEmpty(errors)) {
            const { fields } = this.state;

            const data: ArticleData = new ArticleData(
                fields.title,
                fields.lead,
                fields.content,
                fields.photoFilename,
                fields.detailPhotoFilename,
                fields.publicationDate,
                fields.sendPushNotification
            );

            const {
                dispatchSetInProgressState,
                dispatchSetSuccessState,
                dispatchSetFailState,
                dispatchShowMessage,
                dispatchShowError,
                history,
            } = this.props;

            try {
                const articleId = parseInt(this.props.match.params.articleId, 10);
                let response: ArticleDetails;
                dispatchSetInProgressState();
                if (articleId === 0) {
                    response = await API.articles.add(data);
                } else {
                    response = await API.articles.edit(articleId, data);
                }
                dispatchSetSuccessState();

                history.replace(routesDetails.authenticated.article.to(response.id));

                if (articleId === 0) {
                    dispatchShowMessage(strings.message.added);
                } else {
                    dispatchShowMessage(strings.message.edited);
                }
            } catch (error) {
                dispatchSetFailState();
                if (error !== null) {
                    const message = error.message ? error.message : strings.error.unknown;
                    dispatchShowError(message);
                }
            }
        }
    };

    private validateFormFields(fields: FormFields): FormErrors {
        const errors: FormErrors = {};

        if (Validator.isEmpty(fields.title)) {
            errors.title = strings.error.titleRequired;
        } else if (!Validator.isLength(fields.title, 1, 128)) {
            errors.title = strings.error.titleLength;
        }

        if (Validator.isEmpty(fields.lead)) {
            errors.lead = strings.error.leadRequired;
        } else if (!Validator.isLength(fields.lead, 1, 512)) {
            errors.lead = strings.error.leadLength;
        }

        if (Validator.isEmpty(fields.content)) {
            errors.content = strings.error.contentRequired;
        }

        return errors;
    }
    // #endregion
}

// #region Connect
interface ActionDispatches {
    dispatchSetInProgressState: ActionCreator<SetInProgressStateAction>;
    dispatchSetSuccessState: ActionCreator<SetSuccessStateAction>;
    dispatchSetFailState: ActionCreator<SetFailStateAction>;
    dispatchShowError: ActionCreator<ShowErrorAction>;
    dispatchShowMessage: ActionCreator<ShowMessageAction>;
}

interface ActionCreators
    extends ActionCreatorsMapObject<
        SetInProgressStateAction | SetSuccessStateAction | SetFailStateAction | ShowErrorAction | ShowMessageAction
    > {
    dispatchSetInProgressState: ActionCreator<SetInProgressStateAction>;
    dispatchSetSuccessState: ActionCreator<SetSuccessStateAction>;
    dispatchSetFailState: ActionCreator<SetFailStateAction>;
    dispatchShowError: ActionCreator<ShowErrorAction>;
    dispatchShowMessage: ActionCreator<ShowMessageAction>;
}

const mapDispatchToProps = (dispatch: Dispatch): ActionDispatches =>
    bindActionCreators<ActionCreators>(
        {
            dispatchSetFailState: setFailState,
            dispatchSetInProgressState: setInProgressState,
            dispatchSetSuccessState: setSuccessState,
            dispatchShowError: showError,
            dispatchShowMessage: showMessage,
        },
        dispatch
    );

const mapStateToProps: MapStateToPropsParam<StateProps, {}, StoreState> = (state: StoreState): StateProps => ({
    error: state.error,
    message: state.message,
});

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
    mapStateToProps,
    mapDispatchToProps
)(withAppCanvas(ArticleDetailsPage));
// #endregion
