import { NotificationCategory } from '../types';

export class NotificationData {
    constructor(
        readonly title: string,
        readonly content: string,
        readonly publicationDate: string | null,
        readonly category: NotificationCategory,
        readonly entityId: number | null
    ) {}
}
