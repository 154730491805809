import {
    Button,
    createMuiTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Snackbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ActionCreator, ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import { ThunkActionDispatch } from 'redux-thunk';
import Routes from '../../routes/Routes';
import { StoreState } from '../../store';
import {
    clearError,
    ClearErrorAction,
    hideMessage,
    HideMessageAction,
    LogOutUserAsync,
    logoutUserAsync,
    LogOutUserAsyncAction,
} from '../../store/actions';
import Loading from './Loading';

// #region UI
const strings = {
    button: {
        ok: 'OK',
    },
};

const theme = createMuiTheme({});
// #endregion

// #region Props & State
interface StateProps {
    readonly error: Error | null;
    readonly message: string | null;
}
interface DispatchProps {
    readonly dispatchLogoutUserAsync: LogOutUserAsync;
    readonly dispatchClearError: () => void;
    readonly dispatchHideMessage: () => void;
}
interface OwnProps {}
type ComponentProps = StateProps & DispatchProps & OwnProps;
// #endregion

class App extends React.Component<ComponentProps> {
    // #region Lifecycle
    public render(): React.ReactNode {
        const { error, message } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <Routes />
                <Loading />
                <Dialog open={error !== null} disableBackdropClick={true} disableEscapeKeyDown={true}>
                    <DialogContent>
                        <DialogContentText>{error !== null ? error.message : null}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onDialogOkClick} autoFocus={true}>
                            {strings.button.ok}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom',
                    }}
                    open={message !== null}
                    autoHideDuration={6000}
                    onClose={this.onMessageClose}
                    message={<span>{message}</span>}
                    action={[
                        <IconButton key="close" color="inherit" onClick={this.onMessageClose}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </ThemeProvider>
        );
    }
    // #endregion

    // #region Private
    private onDialogOkClick = async (): Promise<void> => {
        const { error, dispatchLogoutUserAsync, dispatchClearError } = this.props;
        dispatchClearError();
        if (error !== null && error.name === '401') {
            await dispatchLogoutUserAsync();
        }
    };

    private onMessageClose = (): void => {
        const { dispatchHideMessage } = this.props;
        dispatchHideMessage();
    };
    // #endregion
}

// #region Connect
interface ActionDispatches {
    dispatchLogoutUserAsync: ThunkActionDispatch<ActionCreator<LogOutUserAsyncAction>>;
    dispatchClearError: ActionCreator<ClearErrorAction>;
    dispatchHideMessage: ActionCreator<HideMessageAction>;
}

interface ActionCreators extends ActionCreatorsMapObject<LogOutUserAsyncAction | ClearErrorAction | HideMessageAction> {
    dispatchLogoutUserAsync: ActionCreator<LogOutUserAsyncAction>;
    dispatchClearError: ActionCreator<ClearErrorAction>;
    dispatchHideMessage: ActionCreator<HideMessageAction>;
}

const mapDispatchToProps = (dispatch: Dispatch): ActionDispatches =>
    bindActionCreators<ActionCreators>(
        {
            dispatchClearError: clearError,
            dispatchHideMessage: hideMessage,
            dispatchLogoutUserAsync: logoutUserAsync,
        },
        dispatch
    );

const mapStateToProps: MapStateToPropsParam<StateProps, {}, StoreState> = (state: StoreState): StateProps => ({
    error: state.error,
    message: state.message,
});

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
    mapStateToProps,
    mapDispatchToProps
)(App);
// #endregion
