import {
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ActionCreator, ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import { API, SettingKey } from '../../api';
import { Setting } from '../../api/response';
import withAppCanvas, { AppCanvasComponentProps } from '../../components/AppCanvas';
import { MenuItemType } from '../../model/menuItems';
import { StoreState } from '../../store';
import {
    setFailState,
    SetFailStateAction,
    setInProgressState,
    SetInProgressStateAction,
    setSuccessState,
    SetSuccessStateAction,
    showError,
    ShowErrorAction,
    showMessage,
    ShowMessageAction,
} from '../../store/actions';
import SettingCheckboxTableRow from './SettingCheckboxTableRow';
import SettingInputTableRow from './SettingInputTableRow';

// #region UI
const strings = {
    button: {
        refresh: 'Odśwież',
    },
    column: {
        description: 'Opis',
        value: 'Wartość',
    },
    text: {
        shopAdPromo: 'Promocja zakupu usunięcia reklam',
        shopDietPromo: 'Promocja zakupu diet',
        shopScannerPromo: 'Promocja zakupu dodatkowych skanowań',
        shopSubscriptionPromo: 'Promocja subskrypcji',
        shopProductViewsBundle: 'Liczba wyświetleń produktów w pakiecie',
    },
    error: {
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
};
// #endregion

// #region Props & State
interface StateProps {}
interface DispatchProps {
    readonly dispatchSetInProgressState: () => void;
    readonly dispatchSetSuccessState: () => void;
    readonly dispatchSetFailState: () => void;
    readonly dispatchShowError: (error: string) => void;
    readonly dispatchShowMessage: (message: string) => void;
}
interface OwnProps extends RouteComponentProps, AppCanvasComponentProps {}
type ComponentProps = StateProps & DispatchProps & OwnProps;

interface ComponentState {
    items: Setting[];
    loading: boolean;
}

const initialState: ComponentState = {
    items: [],
    loading: true,
};
// #endregion

class SettingsPage extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = initialState;

        const { dispatchChangeMenuItem, dispatchSetTitle } = this.props;
        dispatchChangeMenuItem(MenuItemType.Settings);
        dispatchSetTitle(MenuItemType.Settings);

        this.load();
    }

    // #region Lifecycle
    public render(): React.ReactNode {
        const { loading, items } = this.state;

        if (loading) {
            return (
                <Grid container={true} justify="center">
                    <CircularProgress />
                </Grid>
            );
        }

        return (
            <Paper elevation={2}>
                <Toolbar>
                    <Tooltip title={strings.button.refresh}>
                        <IconButton onClick={this.onRefreshClick}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                <Table>
                    <colgroup>
                        <col width="30%" />
                        <col width="70%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>{strings.column.description}</TableCell>
                            <TableCell>{strings.column.value}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{items.map(this.renderItem)}</TableBody>
                </Table>
            </Paper>
        );
    }
    // #endregion

    // #region Private
    private load = async (): Promise<void> => {
        try {
            const items = await API.settings.list();
            this.setState({ loading: false, items });
        } catch (error) {
            this.setState({ loading: false });
        }
    };

    private renderItem = (item: Setting): React.ReactNode => {
        if (item.key === SettingKey.ShopProductViewsBundle) {
            return (
                <SettingInputTableRow
                    key={item.key}
                    item={item}
                    description={this.keyText(item.key)}
                    onItemUpdate={this.onItemUpdate}
                />
            );
        } else {
            return (
                <SettingCheckboxTableRow
                    key={item.key}
                    item={item}
                    description={this.keyText(item.key)}
                    onItemUpdate={this.onItemUpdate}
                />
            );
        }
    };

    private onItemUpdate = async (key: string, value: string): Promise<void> => {
        const {
            dispatchSetInProgressState,
            dispatchSetSuccessState,
            dispatchSetFailState,
            dispatchShowError,
        } = this.props;

        try {
            dispatchSetInProgressState();
            await API.settings.update(key, value);
            await this.load();
            dispatchSetSuccessState();
        } catch (error) {
            dispatchSetFailState();
            if (error !== null) {
                const message = error.message ? error.message : strings.error.unknown;
                dispatchShowError(message);
            }
        }
    };

    private onRefreshClick = async (): Promise<void> => {
        this.setState({ loading: true, items: [] });
        await this.load();
    };

    private keyText = (key: string): string => {
        if (key === SettingKey.AdPromo) {
            return strings.text.shopAdPromo;
        } else if (key === SettingKey.DietPromo) {
            return strings.text.shopDietPromo;
        } else if (key === SettingKey.ScannerPromo) {
            return strings.text.shopScannerPromo;
        } else if (key === SettingKey.SubscriptionPromo) {
            return strings.text.shopSubscriptionPromo;
        } else if (key === SettingKey.ShopProductViewsBundle) {
            return strings.text.shopProductViewsBundle;
        } else {
            return '';
        }
    };
    // #endregion
}

// #region Connect
interface ActionDispatches {
    dispatchSetInProgressState: ActionCreator<SetInProgressStateAction>;
    dispatchSetSuccessState: ActionCreator<SetSuccessStateAction>;
    dispatchSetFailState: ActionCreator<SetFailStateAction>;
    dispatchShowError: ActionCreator<ShowErrorAction>;
    dispatchShowMessage: ActionCreator<ShowMessageAction>;
}

interface ActionCreators
    extends ActionCreatorsMapObject<
        SetInProgressStateAction | SetSuccessStateAction | SetFailStateAction | ShowErrorAction | ShowMessageAction
    > {
    dispatchSetInProgressState: ActionCreator<SetInProgressStateAction>;
    dispatchSetSuccessState: ActionCreator<SetSuccessStateAction>;
    dispatchSetFailState: ActionCreator<SetFailStateAction>;
    dispatchShowError: ActionCreator<ShowErrorAction>;
    dispatchShowMessage: ActionCreator<ShowMessageAction>;
}

const mapDispatchToProps = (dispatch: Dispatch): ActionDispatches =>
    bindActionCreators<ActionCreators>(
        {
            dispatchSetFailState: setFailState,
            dispatchSetInProgressState: setInProgressState,
            dispatchSetSuccessState: setSuccessState,
            dispatchShowError: showError,
            dispatchShowMessage: showMessage,
        },
        dispatch
    );

const mapStateToProps: MapStateToPropsParam<StateProps, {}, StoreState> = (state: StoreState): StateProps => ({
    error: state.error,
    message: state.message,
});

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
    mapStateToProps,
    mapDispatchToProps
)(withAppCanvas(SettingsPage));
// #endregion
