import {
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ActionCreator, ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import { API } from '../../api';
import { ArticleItem } from '../../api/response';
import withAppCanvas, { AppCanvasComponentProps } from '../../components/AppCanvas';
import { MenuItemType } from '../../model/menuItems';
import { routesDetails } from '../../routes/routesDetails';
import { StoreState } from '../../store';
import {
    setFailState,
    SetFailStateAction,
    setInProgressState,
    SetInProgressStateAction,
    setSuccessState,
    SetSuccessStateAction,
    showError,
    ShowErrorAction,
    showMessage,
    ShowMessageAction,
} from '../../store/actions';
import ArticleTableRow from './ArticleTableRow';

// #region UI
const strings = {
    button: {
        create: 'Utwórz',
        refresh: 'Odśwież',
    },
    column: {
        actions: 'Akcje',
        id: 'Id',
        isPublished: 'Opublikowano',
        publicationDate: 'Data publikacji',
        title: 'Tytuł',
    },
    error: {
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
};
// #endregion

// #region Props & State
interface StateProps {}
interface DispatchProps {
    readonly dispatchSetInProgressState: () => void;
    readonly dispatchSetSuccessState: () => void;
    readonly dispatchSetFailState: () => void;
    readonly dispatchShowError: (error: string) => void;
    readonly dispatchShowMessage: (message: string) => void;
}
interface OwnProps extends RouteComponentProps, AppCanvasComponentProps {}
type ComponentProps = StateProps & DispatchProps & OwnProps;

interface ComponentState {
    items: ArticleItem[];
    loading: boolean;
}

const initialState: ComponentState = {
    items: [],
    loading: true,
};
// #endregion

class ArticlesPage extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = initialState;

        const { dispatchChangeMenuItem, dispatchSetTitle } = this.props;
        dispatchChangeMenuItem(MenuItemType.Articles);
        dispatchSetTitle(MenuItemType.Articles);
    }

    // #region Lifecycle
    public componentDidMount(): void {
        this.load();
    }

    public render(): React.ReactNode {
        const { loading, items } = this.state;

        if (loading) {
            return (
                <Grid container={true} justify="center">
                    <CircularProgress />
                </Grid>
            );
        }

        return (
            <Paper elevation={2}>
                <Toolbar>
                    <Tooltip title={strings.button.refresh}>
                        <IconButton onClick={this.onRefreshClick}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={strings.button.create}>
                        <IconButton onClick={this.onCreateClick}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                <Table>
                    <colgroup>
                        <col width="10%" />
                        <col width="40%" />
                        <col width="20%" />
                        <col width="10%" />
                        <col width="20%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>{strings.column.id}</TableCell>
                            <TableCell>{strings.column.title}</TableCell>
                            <TableCell>{strings.column.publicationDate}</TableCell>
                            <TableCell>{strings.column.isPublished}</TableCell>
                            <TableCell>{strings.column.actions}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{items.map(this.renderItem)}</TableBody>
                </Table>
            </Paper>
        );
    }
    // #endregion

    // #region Private
    private load = async (): Promise<void> => {
        try {
            const items = await API.articles.list();
            this.setState({ loading: false, items });
        } catch (error) {
            this.setState({ loading: false });
        }
    };

    private renderItem = (item: ArticleItem): React.ReactNode => {
        return (
            <ArticleTableRow
                key={item.id}
                item={item}
                onEditClick={this.onItemEditClick}
                onDeleteClick={this.onItemDeleteClick}
            />
        );
    };

    private onItemEditClick = (item: ArticleItem): void => {
        const { history } = this.props;
        history.push(routesDetails.authenticated.article.to(item.id));
    };

    private onItemDeleteClick = async (item: ArticleItem): Promise<void> => {
        const {
            dispatchSetInProgressState,
            dispatchSetSuccessState,
            dispatchSetFailState,
            dispatchShowError,
        } = this.props;

        try {
            dispatchSetInProgressState();
            await API.articles.delete(item.id);
            dispatchSetSuccessState();

            const { items } = this.state;
            this.setState({ items: items.filter((value) => value.id !== item.id) });
        } catch (error) {
            dispatchSetFailState();
            if (error !== null) {
                const message = error.message ? error.message : strings.error.unknown;
                dispatchShowError(message);
            }
        }
    };

    private onRefreshClick = async (): Promise<void> => {
        this.setState({ loading: true, items: [] });
        await this.load();
    };

    private onCreateClick = (): void => {
        const { history } = this.props;
        history.push(routesDetails.authenticated.article.to(0));
    };
    // #endregion
}

// #region Connect
interface ActionDispatches {
    dispatchSetInProgressState: ActionCreator<SetInProgressStateAction>;
    dispatchSetSuccessState: ActionCreator<SetSuccessStateAction>;
    dispatchSetFailState: ActionCreator<SetFailStateAction>;
    dispatchShowError: ActionCreator<ShowErrorAction>;
    dispatchShowMessage: ActionCreator<ShowMessageAction>;
}

interface ActionCreators
    extends ActionCreatorsMapObject<
        SetInProgressStateAction | SetSuccessStateAction | SetFailStateAction | ShowErrorAction | ShowMessageAction
    > {
    dispatchSetInProgressState: ActionCreator<SetInProgressStateAction>;
    dispatchSetSuccessState: ActionCreator<SetSuccessStateAction>;
    dispatchSetFailState: ActionCreator<SetFailStateAction>;
    dispatchShowError: ActionCreator<ShowErrorAction>;
    dispatchShowMessage: ActionCreator<ShowMessageAction>;
}

const mapDispatchToProps = (dispatch: Dispatch): ActionDispatches =>
    bindActionCreators<ActionCreators>(
        {
            dispatchSetFailState: setFailState,
            dispatchSetInProgressState: setInProgressState,
            dispatchSetSuccessState: setSuccessState,
            dispatchShowError: showError,
            dispatchShowMessage: showMessage,
        },
        dispatch
    );

const mapStateToProps: MapStateToPropsParam<StateProps, {}, StoreState> = (state: StoreState): StateProps => ({
    error: state.error,
    message: state.message,
});

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
    mapStateToProps,
    mapDispatchToProps
)(withAppCanvas(ArticlesPage));
// #endregion
