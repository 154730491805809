import ArticleDetailsPage from '../modules/article/ArticleDetailsPage';
import ArticlesPage from '../modules/article/ArticlesPage';
import CategoriesPage from '../modules/category/CategoriesPage';
import DietDetailsPage from '../modules/diet/DietDetailsPage';
import DietsPage from '../modules/diet/DietsPage';
import NotFoundPage from '../modules/error/NotFoundPage';
import UnauthorizedPage from '../modules/error/UnauthorizedPage';
import HomePage from '../modules/home/HomePage';
import ImportAffiliateLinksPage from '../modules/affiliate/ImportAffiliateLinksPage';
import ImportPage from '../modules/import/ImportPage';
import IngredientDetailsPage from '../modules/ingredient/IngredientDetailsPage';
import IngredientsPage from '../modules/ingredient/IngredientsPage';
import LoginPage from '../modules/login/LoginPage';
import NotificationDetailsPage from '../modules/notification/NotificationDetailsPage';
import NotificationsPage from '../modules/notification/NotificationsPage';
import ImportProductsPage from '../modules/product/ImportProductsPage';
import ProductsPage from '../modules/product/ProductsPage';
import SettingsPage from '../modules/setting/SettingsPage';
import VersionsPage from '../modules/version/VersionsPage';

export const routesDetails = {
    authenticated: {
        affiliate: {
            component: ImportAffiliateLinksPage,
            path: '/import/affiliate',
        },
        article: {
            component: ArticleDetailsPage,
            path: '/articles/:articleId(\\d+)',
            to: (articleId: number): string => `/articles/${articleId}`,
        },
        articles: {
            component: ArticlesPage,
            path: '/articles',
        },
        categories: {
            component: CategoriesPage,
            path: '/categories',
        },
        diet: {
            component: DietDetailsPage,
            path: '/diets/:dietId(\\d+)',
            to: (dietId: number) => `/diets/${dietId}`,
        },
        diets: {
            component: DietsPage,
            path: '/diets',
        },
        homePage: {
            component: HomePage,
            path: '/',
        },
        import: {
            component: ImportPage,
            path: '/import',
        },
        importProducts: {
            component: ImportProductsPage,
            path: '/import/products',
        },
        ingredient: {
            component: IngredientDetailsPage,
            path: '/ingredients/:ingredientId(\\d+)',
            to: (ingredientId: number): string => `/ingredients/${ingredientId}`,
        },
        ingredients: {
            component: IngredientsPage,
            path: '/ingredients',
        },
        notification: {
            component: NotificationDetailsPage,
            path: '/notifications/:notificationId(\\d+)',
            to: (notificationId: number): string => `/notifications/${notificationId}`,
        },
        notifications: {
            component: NotificationsPage,
            path: '/notifications',
        },
        products: {
            component: ProductsPage,
            path: '/products',
        },
        settings: {
            component: SettingsPage,
            path: '/settings',
        },
        versions: {
            component: VersionsPage,
            path: '/versions',
        },
    },
    error: {
        notFound: {
            component: NotFoundPage,
            path: '/error/404',
        },
        unauthorized: {
            component: UnauthorizedPage,
            path: '/error/401',
        },
    },
    guest: {
        login: {
            component: LoginPage,
            path: '/login',
        },
    },
};
