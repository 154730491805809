import { Grid, Typography } from '@material-ui/core';
import React from 'react';

// #region UI
const strings = {
    error: {
        unauthorized: '401 - Brak dostępu',
    },
};
// #endregion

export default class UnauthorizedPage extends React.Component {
    public render(): React.ReactNode {
        return (
            <Grid container={true} justify="center">
                <Grid item={true}>
                    <Typography variant="h5">{strings.error.unauthorized}</Typography>
                </Grid>
            </Grid>
        );
    }
}
