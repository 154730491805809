import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';
import { StoreState } from '../store';
import { routesDetails } from './routesDetails';

/* tslint:disable:no-any */

// #region Props & State
interface StateProps {
    readonly isAuthenticated: boolean;
}
interface DispatchProps {}
interface OwnProps extends RouteProps {
    readonly component: React.ComponentClass<any> | React.StatelessComponent<any>;
    readonly [rest: string]: any;
}
type ComponentProps = StateProps & DispatchProps & OwnProps;
// #endregion

class AuthenticatedRoute extends React.Component<ComponentProps> {
    public render(): React.ReactNode {
        const { isAuthenticated, component, title, ...rest } = this.props;
        const Component = component;

        const render = (renderProps: RouteComponentProps<any>) => {
            if (isAuthenticated) {
                return <Component {...renderProps} />;
            } else {
                return (
                    <Redirect
                        to={{ pathname: routesDetails.guest.login.path, state: { from: renderProps.location } }}
                    />
                );
            }
        };

        return <Route {...rest} render={render} />;
    }
}

// #region Connect
const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, StoreState> = (state: StoreState): StateProps => ({
    isAuthenticated: state.auth !== null,
});

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(mapStateToProps)(AuthenticatedRoute);
// #endregion
