import { Action, ActionCreator } from 'redux';
import { TitleActionType } from './actionTypes';

// #region SetTitle
export interface SetTitleAction extends Action<TitleActionType.SetTitle> {
    title: string;
}

export const setTitle: ActionCreator<SetTitleAction> = (title: string): SetTitleAction => ({
    title,
    type: TitleActionType.SetTitle,
});
// #endregion

// #region ClearTitle
export interface ClearTitleAction extends Action<TitleActionType.ClearTitle> {}

export const clearTitle: ActionCreator<ClearTitleAction> = (): ClearTitleAction => ({
    type: TitleActionType.ClearTitle,
});
// #endregion

export type TitleAction = SetTitleAction | ClearTitleAction;
