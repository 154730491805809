export enum SettingKey {
    DietPromo = 'shop_diet_promo',
    AdPromo = 'shop_ad_promo',
    ScannerPromo = 'shop_scanner_promo',
    SubscriptionPromo = 'shop_subscription_promo',
    ShopProductViewsBundle = 'shop_product_views_bundle',
}

export enum NotificationCategory {
    General = 'general',
    Shop = 'shop',
    Diet = 'diet',
    Ingredient = 'ingredient',
    Article = 'article',
}

export const notificationCategoryText = (notificationCategory: NotificationCategory): string => {
    switch (notificationCategory) {
        case NotificationCategory.General:
            return 'Brak';
        case NotificationCategory.Shop:
            return 'Sklep';
        case NotificationCategory.Diet:
            return 'Dieta';
        case NotificationCategory.Ingredient:
            return 'Składnik';
        case NotificationCategory.Article:
            return 'Artykuł';
        default:
            return '-';
    }
};

export enum DietIngredientInfluence {
    Positive = 'positive',
    Negative = 'negative',
}

export enum DietIngredientRecommendedType {
    No = 0,
    YesGreen = 1,
    YesRed = 2,
    YesBlack = 3,
}

export const dietIngredientInfluenceText = (dietIngredientInfluence: DietIngredientInfluence): string => {
    switch (dietIngredientInfluence) {
        case DietIngredientInfluence.Positive:
            return 'Pozywytny';
        case DietIngredientInfluence.Negative:
            return 'Negatywny';
        default:
            return '-';
    }
};

export enum IngredientType {
    ActiveSubstance = 'active_substance',
    Dish = 'dish',
    Product = 'product',
}

export const ingredientTypeText = (ingredientType: IngredientType): string => {
    switch (ingredientType) {
        case IngredientType.ActiveSubstance:
            return 'Substancja aktywna';
        case IngredientType.Dish:
            return 'Potrawa';
        case IngredientType.Product:
            return 'Produkt';
        default:
            return '-';
    }
};

export enum HealthInfluenceType {
    Positive = 'positive',
    Negative = 'negative',
    Other = 'other',
}

export const healthInfluenceTypeText = (healthInfluenceType: HealthInfluenceType): string => {
    switch (healthInfluenceType) {
        case HealthInfluenceType.Positive:
            return 'Pozywytny';
        case HealthInfluenceType.Negative:
            return 'Negatywny';
        case HealthInfluenceType.Other:
            return 'Inny';
        default:
            return '-';
    }
};

export enum NutritionalValueType {
    Protein = 'protein',
    Fat = 'fat',
    SaturatedFattyAcid = 'saturatedFattyAcid',
    MonounsaturatedFattyAcid = 'monounsaturatedFattyAcid',
    PolyunsaturatedFattyAcid = 'polyunsaturatedFattyAcid',
    Carbohydrate = 'carbohydrate',
    Fibre = 'fibre',
    Sodium = 'sodium',
    Salt = 'salt',
    Potassium = 'potassium',
    Calcium = 'calcium',
    Phosphorus = 'phosphorus',
    Magnesium = 'magnesium',
    Iron = 'iron',
    Zinc = 'zinc',
    Copper = 'copper',
    Manganese = 'manganese',
    Iodine = 'iodine',
    VitaminA = 'vitaminA',
    Retinol = 'retinol',
    BetaCarotene = 'betaCarotene',
    VitaminD = 'vitaminD',
    VitaminE = 'vitaminE',
    Thiamine = 'thiamine',
    Riboflavin = 'riboflavin',
    Niacin = 'niacin',
    VitaminB6 = 'vitaminB6',
    Folate = 'folate',
    VitaminB12 = 'vitaminB12',
    VitaminC = 'vitaminC',
    Cholesterol = 'cholesterol',
}

export const nutritionalValueTypeText = (nutritionalValueType: NutritionalValueType): string => {
    switch (nutritionalValueType) {
        case NutritionalValueType.Protein:
            return 'Białko';
        case NutritionalValueType.Fat:
            return 'Tłuszcz';
        case NutritionalValueType.SaturatedFattyAcid:
            return 'Kwasy tłuszczowe nasycone';
        case NutritionalValueType.MonounsaturatedFattyAcid:
            return 'Kwasy tłuszczowe jednonienasycone';
        case NutritionalValueType.PolyunsaturatedFattyAcid:
            return 'Kwasy tłuszczowe wielonienasycone';
        case NutritionalValueType.Carbohydrate:
            return 'Węglowodany';
        case NutritionalValueType.Fibre:
            return 'Błonnik pokarmowy';
        case NutritionalValueType.Sodium:
            return 'Sód';
        case NutritionalValueType.Salt:
            return 'Sól';
        case NutritionalValueType.Potassium:
            return 'Potas';
        case NutritionalValueType.Calcium:
            return 'Wapń';
        case NutritionalValueType.Phosphorus:
            return 'Fosfor';
        case NutritionalValueType.Magnesium:
            return 'Magnez';
        case NutritionalValueType.Iron:
            return 'Żelazo';
        case NutritionalValueType.Zinc:
            return 'Cynk';
        case NutritionalValueType.Copper:
            return 'Miedź';
        case NutritionalValueType.Manganese:
            return 'Mangan';
        case NutritionalValueType.Iodine:
            return 'Jod';
        case NutritionalValueType.VitaminA:
            return 'Witamina A';
        case NutritionalValueType.Retinol:
            return 'Retinol';
        case NutritionalValueType.BetaCarotene:
            return 'β-karoten';
        case NutritionalValueType.VitaminD:
            return 'Witamina D';
        case NutritionalValueType.VitaminE:
            return 'Witamina E';
        case NutritionalValueType.Thiamine:
            return 'Tiamina';
        case NutritionalValueType.Riboflavin:
            return 'Ryboflawina';
        case NutritionalValueType.Niacin:
            return 'Niacyna';
        case NutritionalValueType.VitaminB6:
            return 'Witamina B₆';
        case NutritionalValueType.Folate:
            return 'Foliany';
        case NutritionalValueType.VitaminB12:
            return 'Witamina B₁₂';
        case NutritionalValueType.VitaminC:
            return 'Witamina C';
        case NutritionalValueType.Cholesterol:
            return 'Cholesterol';
        default:
            return '-';
    }
};
