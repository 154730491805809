import MomentUtils from '@date-io/moment';
import {
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ActionCreator, ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import Validator from 'validator';
import { API, NotificationCategory, notificationCategoryText } from '../../api';
import { NotificationData } from '../../api/request/NotificationData';
import { NotificationDetails } from '../../api/response';
import withAppCanvas, { AppCanvasComponentProps } from '../../components/AppCanvas';
import { MenuItemType } from '../../model/menuItems';
import { routesDetails } from '../../routes/routesDetails';
import { StoreState } from '../../store';
import {
    setFailState,
    SetFailStateAction,
    setInProgressState,
    SetInProgressStateAction,
    setSuccessState,
    SetSuccessStateAction,
    showError,
    ShowErrorAction,
    showMessage,
    ShowMessageAction,
} from '../../store/actions';

// #region UI
const dateTimeFormat: string = 'YYYY-MM-DD HH:mm';

const strings = {
    button: {
        cancel: 'Anuluj',
        clear: 'Wyczyść',
        ok: 'OK',
        save: 'Zapisz',
    },
    error: {
        categoryInvalid: 'Podano błędną kategorię',
        categoryRequired: 'Kategoria jest wymagana',
        contentLength: 'Wymagana wartość w przedziale <1, 512> znaków',
        contentRequired: 'Treść jest wymagana',
        entityIdInvalid: 'Podano błędną wartość identyfikatora obiektu',
        entityIdRequired: 'Identyfikator jest wymagany',
        invalidDateTime: 'Podano błędną datę i/lub czas',
        recipientsRequired: 'Przynajmniej jeden odbiorca jest wymagany',
        titleLength: 'Wymagana wartość w przedziale <1, 128> znaków',
        titleRequired: 'Tytuł jest wymagany',
        unknown: 'Wystąpił niespodziewany błąd, proszę spróbować ponownie.',
    },
    label: {
        category: 'Kategoria',
        content: 'Treść',
        entityId: 'Identyfikator obiektu',
        publicationDate: 'Data publikacji',
        recipients: 'Wyślij do',
        title: 'Tytuł',
        categoryInfo:
            'W zależności od wybranej kategorii, powiadomienie zostanie wysłane do odpowiedniego kanału/tematu.',
    },
    message: {
        added: 'Nowe powiadomienie zostało dodane',
        edited: 'Powiadomienie zostało zaktualizowane',
    },
};
// #endregion

// #region Form
enum FormFieldNames {
    Title = 'title',
    Content = 'content',
    PublicationDate = 'publicationDate',
    Category = 'category',
    EntityId = 'entityId',
}

interface FormFields {
    title: string;
    content: string;
    publicationDate: string | null;
    category: NotificationCategory;
    entityId: string;
}

interface FormErrors {
    title?: string;
    content?: string;
    recipients?: string;
    category?: string;
    entityId?: string;
}
// #endregion

// #region Props & State
interface StateProps {}
interface DispatchProps {
    readonly dispatchSetInProgressState: () => void;
    readonly dispatchSetSuccessState: () => void;
    readonly dispatchSetFailState: () => void;
    readonly dispatchShowError: (error: string) => void;
    readonly dispatchShowMessage: (message: string) => void;
}
interface RouteParams {
    notificationId: string;
}
interface OwnProps extends RouteComponentProps<RouteParams>, AppCanvasComponentProps {}
type ComponentProps = StateProps & DispatchProps & OwnProps;

interface ComponentState {
    loading: boolean;
    loaded: boolean;
    errors: FormErrors;
    fields: FormFields;
}

const initialState: ComponentState = {
    errors: {},
    fields: {
        category: NotificationCategory.General,
        content: '',
        entityId: '',
        publicationDate: null,
        title: '',
    },
    loaded: false,
    loading: false,
};
// #endregion

class NotificationDetailsPage extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = initialState;

        const { dispatchChangeMenuItem, dispatchSetTitle } = this.props;
        dispatchChangeMenuItem(MenuItemType.Notifications);
        dispatchSetTitle(MenuItemType.Notifications);
    }

    // #region Lifecycle
    public componentDidMount(): void {
        this.load();
    }

    public render(): React.ReactNode {
        const { loading, loaded, errors, fields } = this.state;

        if (loading) {
            return (
                <Grid container={true} justify="center">
                    <CircularProgress />
                </Grid>
            );
        }

        if (!loaded) {
            return (
                <Grid container={true} justify="center">
                    <Typography color="error">{strings.error.unknown}</Typography>
                </Grid>
            );
        }

        return (
            <React.Fragment>
                <Grid container={true} spacing={1} direction="row">
                    <Grid item={true} xs={12}>
                        <TextField
                            error={errors.title !== undefined}
                            helperText={errors.title}
                            id={FormFieldNames.Title}
                            name={FormFieldNames.Title}
                            value={fields.title}
                            multiline={false}
                            required={true}
                            inputProps={{ maxLength: 128 }}
                            fullWidth={true}
                            label={strings.label.title}
                            margin="none"
                            onChange={this.onTextFieldChange}
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <TextField
                            error={errors.content !== undefined}
                            helperText={errors.content}
                            id={FormFieldNames.Content}
                            name={FormFieldNames.Content}
                            value={fields.content}
                            inputProps={{ maxLength: 512 }}
                            margin="normal"
                            multiline={true}
                            rows={5}
                            required={true}
                            fullWidth={true}
                            label={strings.label.content}
                            onChange={this.onTextFieldChange}
                        />
                    </Grid>

                    <Grid item={true} xs={12}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                                onChange={this.onDateTimeChange}
                                value={
                                    fields.publicationDate !== null
                                        ? moment(fields.publicationDate).format(dateTimeFormat)
                                        : null
                                }
                                allowKeyboardControl={true}
                                ampm={false}
                                autoOk={true}
                                disableFuture={false}
                                disablePast={false}
                                format={dateTimeFormat}
                                inputVariant="standard"
                                invalidDateMessage={strings.error.invalidDateTime}
                                variant="dialog"
                                margin="normal"
                                label={strings.label.publicationDate}
                                cancelLabel={strings.button.cancel}
                                clearable={true}
                                clearLabel={strings.button.clear}
                                okLabel={strings.button.ok}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item={true} xs={6}>
                        <FormControl
                            fullWidth={true}
                            margin="normal"
                            error={errors.category !== undefined}
                            required={true}
                        >
                            <InputLabel>{strings.label.category}</InputLabel>
                            <Select
                                value={fields.category}
                                onChange={this.onSelectChange}
                                inputProps={{
                                    id: FormFieldNames.Category,
                                    name: FormFieldNames.Category,
                                }}
                            >
                                <MenuItem value={NotificationCategory.General}>
                                    {notificationCategoryText(NotificationCategory.General)}
                                </MenuItem>
                                <MenuItem value={NotificationCategory.Shop}>
                                    {notificationCategoryText(NotificationCategory.Shop)}
                                </MenuItem>
                                <MenuItem value={NotificationCategory.Diet}>
                                    {notificationCategoryText(NotificationCategory.Diet)}
                                </MenuItem>
                                <MenuItem value={NotificationCategory.Ingredient}>
                                    {notificationCategoryText(NotificationCategory.Ingredient)}
                                </MenuItem>
                                <MenuItem value={NotificationCategory.Article}>
                                    {notificationCategoryText(NotificationCategory.Article)}
                                </MenuItem>
                            </Select>
                            {errors.category !== undefined && <FormHelperText>{errors.category}</FormHelperText>}
                        </FormControl>
                        <Typography variant="caption">{strings.label.categoryInfo}</Typography>
                    </Grid>

                    <Grid item={true} xs={6}>
                        {fields.category !== NotificationCategory.General &&
                            fields.category !== NotificationCategory.Shop && (
                                <TextField
                                    error={errors.entityId !== undefined}
                                    helperText={errors.entityId}
                                    id={FormFieldNames.EntityId}
                                    name={FormFieldNames.EntityId}
                                    value={fields.entityId}
                                    multiline={false}
                                    required={true}
                                    fullWidth={true}
                                    label={strings.label.entityId}
                                    margin="normal"
                                    onChange={this.onTextFieldChange}
                                />
                            )}
                    </Grid>

                    <Grid item={true}>
                        <Button color="primary" variant="contained" onClick={this.onSaveClick}>
                            {strings.button.save}
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
    // #endregion

    // #region Private
    private load = async (): Promise<void> => {
        const { notificationId } = this.props.match.params;
        const id: number = parseInt(notificationId, 10);

        if (id === 0) {
            this.setState({ loading: false, loaded: true });
        } else {
            try {
                this.setState({ loading: true });
                const response = await API.notifications.details(id);
                this.setState({
                    fields: {
                        category: response.category,
                        content: response.content,
                        entityId: response.entityId !== null ? response.entityId.toString(10) : '',
                        publicationDate:
                            response.publicationDate !== null
                                ? moment(response.publicationDate).format(dateTimeFormat)
                                : null,
                        title: response.title,
                    },
                    loaded: true,
                    loading: false,
                });
            } catch (error) {
                this.setState({ loading: false });
            }
        }
    };

    private onTextFieldChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>
    ): void => {
        if (
            event.target.name === FormFieldNames.Category &&
            (event.target.value === NotificationCategory.General || event.target.value === NotificationCategory.Shop)
        ) {
            this.setState({
                fields: { ...this.state.fields, [event.target.name]: event.target.value, entityId: '' },
            });
        } else {
            this.setState({
                fields: { ...this.state.fields, [event.target.name]: event.target.value },
            });
        }
    };

    private onSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
        if (event.target.name !== undefined) {
            this.setState({
                fields: { ...this.state.fields, [event.target.name]: event.target.value },
            });
        }
    };

    private onDateTimeChange = (date: MaterialUiPickersDate): void => {
        this.setState({
            fields: {
                ...this.state.fields,
                publicationDate: date !== null && date.isValid() ? date.format(dateTimeFormat) : null,
            },
        });
    };

    private onSaveClick = async (): Promise<void> => {
        const errors: FormErrors = this.validateFormFields(this.state.fields);
        this.setState({ errors });

        if (_.isEmpty(errors)) {
            const { fields } = this.state;

            const data: NotificationData = new NotificationData(
                fields.title,
                fields.content,
                fields.publicationDate,
                fields.category,
                fields.category !== NotificationCategory.General && fields.category !== NotificationCategory.Shop
                    ? parseInt(fields.entityId, 10)
                    : null
            );

            const {
                dispatchSetInProgressState,
                dispatchSetSuccessState,
                dispatchSetFailState,
                dispatchShowMessage,
                dispatchShowError,
                history,
            } = this.props;

            try {
                const notificationId = parseInt(this.props.match.params.notificationId, 10);
                let response: NotificationDetails;
                dispatchSetInProgressState();
                if (notificationId === 0) {
                    response = await API.notifications.add(data);
                } else {
                    response = await API.notifications.edit(notificationId, data);
                }
                dispatchSetSuccessState();

                history.replace(routesDetails.authenticated.notification.to(response.id));

                if (notificationId === 0) {
                    dispatchShowMessage(strings.message.added);
                } else {
                    dispatchShowMessage(strings.message.edited);
                }
            } catch (error) {
                dispatchSetFailState();
                if (error !== null) {
                    const message = error.message ? error.message : strings.error.unknown;
                    dispatchShowError(message);
                }
            }
        }
    };

    private validateFormFields(fields: FormFields): FormErrors {
        const errors: FormErrors = {};

        if (Validator.isEmpty(fields.title)) {
            errors.title = strings.error.titleRequired;
        } else if (!Validator.isLength(fields.title, 1, 128)) {
            errors.title = strings.error.titleLength;
        }

        if (Validator.isEmpty(fields.content)) {
            errors.content = strings.error.contentRequired;
        } else if (!Validator.isLength(fields.content, 1, 512)) {
            errors.content = strings.error.contentLength;
        }

        if (Validator.isEmpty(fields.category)) {
            errors.category = strings.error.categoryRequired;
        } else if (!Validator.isIn(fields.category, _.values(NotificationCategory))) {
            errors.category = strings.error.categoryInvalid;
        }

        if (fields.category !== NotificationCategory.General && fields.category !== NotificationCategory.Shop) {
            if (Validator.isEmpty(fields.entityId)) {
                errors.entityId = strings.error.entityIdRequired;
            } else if (!Validator.isInt(fields.entityId)) {
                errors.entityId = strings.error.entityIdInvalid;
            }
        }

        return errors;
    }
    // #endregion
}

// #region Connect
interface ActionDispatches {
    dispatchSetInProgressState: ActionCreator<SetInProgressStateAction>;
    dispatchSetSuccessState: ActionCreator<SetSuccessStateAction>;
    dispatchSetFailState: ActionCreator<SetFailStateAction>;
    dispatchShowError: ActionCreator<ShowErrorAction>;
    dispatchShowMessage: ActionCreator<ShowMessageAction>;
}

interface ActionCreators
    extends ActionCreatorsMapObject<
        SetInProgressStateAction | SetSuccessStateAction | SetFailStateAction | ShowErrorAction | ShowMessageAction
    > {
    dispatchSetInProgressState: ActionCreator<SetInProgressStateAction>;
    dispatchSetSuccessState: ActionCreator<SetSuccessStateAction>;
    dispatchSetFailState: ActionCreator<SetFailStateAction>;
    dispatchShowError: ActionCreator<ShowErrorAction>;
    dispatchShowMessage: ActionCreator<ShowMessageAction>;
}

const mapDispatchToProps = (dispatch: Dispatch): ActionDispatches =>
    bindActionCreators<ActionCreators>(
        {
            dispatchSetFailState: setFailState,
            dispatchSetInProgressState: setInProgressState,
            dispatchSetSuccessState: setSuccessState,
            dispatchShowError: showError,
            dispatchShowMessage: showMessage,
        },
        dispatch
    );

const mapStateToProps: MapStateToPropsParam<StateProps, {}, StoreState> = (state: StoreState): StateProps => ({
    error: state.error,
    message: state.message,
});

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
    mapStateToProps,
    mapDispatchToProps
)(withAppCanvas(NotificationDetailsPage));
// #endregion
