import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ActionCreator, ActionCreatorsMapObject, bindActionCreators, Dispatch } from 'redux';
import { ThunkActionDispatch } from 'redux-thunk';
import { routesDetails } from '../../routes/routesDetails';
import { StoreState } from '../../store';
import { AuthenticateUserAsync, authenticateUserAsync, AuthenticateUserAsyncAction } from '../../store/actions';
import LoginForm from './LoginForm';
import LoginHeader from './LoginHeader';

// #region Props & State
interface StateProps {}
interface DispatchProps {
    readonly dispatchAuthenticateUserAsync: AuthenticateUserAsync;
}
interface RouteParams {}
interface OwnProps extends RouteComponentProps<RouteParams> {}
type ComponentProps = StateProps & DispatchProps & OwnProps;
// #endregion

class LoginPage extends React.Component<ComponentProps> {
    // #region Lifecycle
    public render(): React.ReactNode {
        return (
            <React.Fragment>
                <LoginHeader />
                <LoginForm submit={this.submit} />
            </React.Fragment>
        );
    }
    // #endregion

    // #region Private
    private submit = async (userName: string, password: string): Promise<void> => {
        const { dispatchAuthenticateUserAsync, history } = this.props;

        await dispatchAuthenticateUserAsync(userName, password);
        history.push(routesDetails.authenticated.homePage.path);
    };
    // #endregion
}

// #region Connect
interface ActionDispatches {
    dispatchAuthenticateUserAsync: ThunkActionDispatch<ActionCreator<AuthenticateUserAsyncAction>>;
}

interface ActionCreators extends ActionCreatorsMapObject<AuthenticateUserAsyncAction> {
    dispatchAuthenticateUserAsync: ActionCreator<AuthenticateUserAsyncAction>;
}

const mapDispatchToProps = (dispatch: Dispatch): ActionDispatches =>
    bindActionCreators<ActionCreators>(
        {
            dispatchAuthenticateUserAsync: authenticateUserAsync,
        },
        dispatch
    );

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
    null,
    mapDispatchToProps
)(LoginPage);
// #endregion
