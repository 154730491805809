import { Reducer } from 'redux';
import { TitleAction, TitleActionType } from '../actions';

type ReducerState = string | null;
type ReducerAction = TitleAction;

const initialState: ReducerState = null;

export const title: Reducer<ReducerState, ReducerAction> = (
    state: ReducerState = initialState,
    action: ReducerAction
): ReducerState => {
    switch (action.type) {
        case TitleActionType.SetTitle:
            return action.title;
        case TitleActionType.ClearTitle:
            return null;
        default:
            return state;
    }
};
