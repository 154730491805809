import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Setting } from '../../api/response';

const valueChecked: string = '1';
const valueUnchecked: string = '0';

// #region Props & State
interface ComponentProps {
    item: Setting;
    description: string;
    onItemUpdate: (key: string, value: string) => void;
}

interface ComponentState {
    checked: boolean;
}

const initialState = (value: string): ComponentState => ({
    checked: value === valueChecked,
});
// #endregion

class SettingTableRow extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        const { item } = props;
        this.state = initialState(item.value);
    }

    // #region Lifecycle
    public render(): React.ReactNode {
        const { checked } = this.state;
        const { description } = this.props;

        return (
            <TableRow hover={true}>
                <TableCell>{description}</TableCell>
                <TableCell>
                    <Checkbox checked={checked} onChange={this.onItemChange} color="primary" />
                </TableCell>
            </TableRow>
        );
    }
    // #endregion

    // #region Private
    // tslint:disable-next-line
    private onItemChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        const { item, onItemUpdate } = this.props;
        onItemUpdate(item.key, checked ? valueChecked : valueUnchecked);
        this.setState({ checked });
    };
    // #endregion
}

export default SettingTableRow;
